import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_CARD,
    SET_CARD,
    SET_TIMER,
    SET_WINNER
} from "@actions/actionTypes";
import {PostResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";
import initialStore from "../initialStore";

function* sendCard({code}) {
    const body = {
        code
    }
    const cardInfo =  yield call(PostResource, `/round/addcard` , body);
    yield put({
        type: SET_CARD,
        code
    });
}

function* cardSaga() {
    yield takeLatest(REQUEST_CARD,safe(onError, sendCard));

}

export default cardSaga;