import {
    SET_DEALER_HAND,
    ADD_DEALER_CARD,
    CANCEL_ROUND,
    SET_ROUND_END
} from "@actions/actionTypes";
import initialStore from "../initialStore";

export default function dealerHandReducer(state = initialStore.dealerHand, action) {
    switch (action.type) {
        case SET_DEALER_HAND:
            return action.dealerHand;
        case ADD_DEALER_CARD:
            return {
                cards: action.hand[0].Cards,
                handId: action.hand[0].HandId,
                owner: action.hand[0].Owner,
                points:  action.hand[0].Points
            };
        case CANCEL_ROUND:
        case SET_ROUND_END:
            return initialStore.dealerHand;
        default:
            return state;
    }
}
