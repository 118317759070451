import { ActiveAreaTypes } from "../constants/Enums";

export default {
    settings: {
        lang: {
            id: 1
        },
        gameConfig: {
            autoStart: false,
            autoResult: false,
            timer: 15
        }
    },
    user: {},
    emptyObj: {},
    emptyArr: [],
    playersList: {
        list: [],
        listModalVisible: false,
        isLoading: false
    },
    allowCancel: {
        allowBets: false,
        allowTransaction: false
    },
    cards: {
        bankerCards: [{}, {}, {}],
        bankerCardWeight: 0,
        playerCards: [{}, {}, {}],
        playerCardWeight: 0,
    },
    timer:{
        timerStart: undefined,
        timerEnd: undefined
    },
    activeArea: {
        Owner: ActiveAreaTypes.none
    },
    globalInfo: {
        isConected: false
    },
    playerHands: null,
    dealerHand: null,
    isActiveResult: false
}