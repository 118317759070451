import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {Divider, Layout} from 'antd';
import "./style.sass";
import {FormattedMessage, injectIntl} from "react-intl"
import {getLiveTime} from "@services/timeService";

const { Header } = Layout;

function MainHeader({intl, roundId}) {
    const [currentTime, setCurrentTime] = useState("")
    const currentTimeRef = useRef();

    useEffect(() => {
        currentTimeRef.current = setInterval(() => {setCurrentTime(getLiveTime(intl))}, 1000)
        return () => clearInterval(currentTimeRef.current)
    }, [])

    return (
        <Header className="site-layout-background header">
            <div >
                <div className="time-block centeredBox">
                    <span>Dealer Admin</span>
                </div>
                <div className="header-account">
                    {roundId ? <div className="info-box"><FormattedMessage id="round_id"/> {roundId}  </div> :"" }
                    <div className="info-box">
                        <Divider type="vertical"/>{currentTime}
                    </div>
                </div>
            </div>
        </Header>
    )
}

const mapStateToProps = (state) => {
    return {
        roundId: state.round.roundId
    }
}

export default injectIntl(connect(mapStateToProps, null)(MainHeader));