import React, { useState} from 'react'
import { Layout } from 'antd';
import MainHeader from '../../components/Header'
import routes from "../../routes";
import {
    Switch,
    Redirect,
    Route,
  } from "react-router-dom";
import "./style.sass";
import AlertMessage from "@components/AlertMessage/AlertMessage";
import {connect} from "react-redux";

const {  Content } = Layout;

function MainPage({path}) {
    return (
        <>
        <MainHeader />
        <Layout className="site-layout">
            <AlertMessage />
            <Content
                className="site-layout-background"
                style={{
                    margin: 24,
                    padding: '0 24px',
                    minHeight: 'unset',
                    }}
            >
                <Switch>
                        {routes.map((route, i) => (
                            <Route
                                key={i}
                                path={route.path}
                                render={props => (
                                    <route.component {...props}/>
                                )}
                            />
                        ))}

                        <Route exact path="/:all" render={() => <Redirect to={routes[0].path} />}/>
                    </Switch>
            </Content>
        </Layout>
        </>
    )
}

function mapStateToProps(state) {
    return {
        path: state.path,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);