import TimeWorker from "../workers/TimeTicker.worker.js";
import WorkerBuilder from "../workers/WorkerBuilder";

function WorkerService() {
    this.timeTickerWorker = new WorkerBuilder(TimeWorker);
    let subscribes = [];


    this.timeTickerWorker.onmessage = function (messageEvent) {
        subscribes.forEach(subscribe => {
            subscribe(messageEvent);
        })
    }

    this.setTimeTicker = function (timeTicker) {
        this.timeTickerWorker.postMessage(JSON.stringify({
            type: "SET_TIME_TICKER",
            data: timeTicker
        }))
    }

    this.getTime = function () {
        this.timeTickerWorker.postMessage(JSON.stringify({
            type: "GET_SERVER_TIME",
        }))
    }
    this.subscribe = function (f) {
        subscribes.push(f);
    }

    this.unSubscribe = function (f) {
        subscribes = subscribes.filter(item => item !== f);
    }

    this.setTime = function (time) {
        this.timeTickerWorker.postMessage(JSON.stringify({
            type: "SET_SERVER_TIME",
            data: time
        }))
    }
}


export default WorkerService;