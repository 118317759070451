import {
    REQUEST_CARD,
    SET_OPEN_DEALER_CLOSED_CARD
 } from "@actions/actionTypes";

export function scanCard(code) {
    return dispatch => {
        dispatch({type: REQUEST_CARD, code})
    }
}

export function openDealerCard() {
    return dispatch => {
        dispatch({type: SET_OPEN_DEALER_CLOSED_CARD, isEnable: true})
    }
}

export function closeDealerCard() {
    return dispatch => {
        dispatch({type: SET_OPEN_DEALER_CLOSED_CARD, isEnable: false})
    }
}