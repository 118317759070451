import {
    SET_SUB_TICKER_WORKER,
    SET_UN_SUB_TICKER_WORKER,
    GET_SERVER_TIME
} from "@actions/actionTypes";

export function subscribeTickerWorker(setServerTime) {
    return dispatch => {
        dispatch({
            type: SET_SUB_TICKER_WORKER,
            data: setServerTime
        })
    }
}

export function unSubscribeTickerWorker(setServerTime) {
    return dispatch => {
        dispatch({
            type: SET_UN_SUB_TICKER_WORKER,
            data: setServerTime
        })
    }
}

export function getTimeFromTickerWorker() {
    return dispatch => {
        dispatch({
            type: GET_SERVER_TIME
        })
    }
}