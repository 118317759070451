import {
    SET_PLAYER_HANDS,
    ADD_PLAYER_CARD,
    CANCEL_ROUND,
    SET_ROUND_END
} from "@actions/actionTypes";
import initialStore from "../initialStore";
import {
    OwnerTypes
} from "@constants/Enums";

export default function playerHandsReducer(state = initialStore.playerHands, action) {
    switch (action.type) {
        case SET_PLAYER_HANDS:
            return action.playerHands;
        case ADD_PLAYER_CARD:
            if (state) {
                const hand = [
                    {
                        cards: action.hand[0].Cards,
                        handId: action.hand[0].HandId,
                        owner: action.hand[0].Owner,
                        points:  action.hand[0].Points
                    }
                ];
                if (action.hand[1]) {
                    hand[1] = {
                        cards: action.hand[1].Cards,
                        handId: action.hand[1].HandId,
                        owner: action.hand[1].Owner,
                        points:  action.hand[1].Points
                    }
                }
                return hand;
            }
            return [
                {
                    cards: action.hand[0].Cards,
                    handId: action.hand[0].HandId,
                    owner: action.hand[0].Owner,
                    points:  action.hand[0].Points
                }
            ]
        case CANCEL_ROUND:
        case SET_ROUND_END:
            return initialStore.playerHands;
        default:
            return state;
    }
}