export const DefaultLanguage = 'EN';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];

export const cardName = [
     "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K", "A"
]

export const CardSuit = {
    0: "none", 
    1: "clubs",
    2: "diamonds",
    3: "hearts",
    4: "spades"
}

export const playerTypes = {
    0: "banker",
    1: "player",
    "banker": 0,
    "player": 1
}

export const winnerTypes = {
    0: "none",
    1: "banker",
    2: "player",
    3: "tie",
    "none": 0,
    "banker": 1,
    "player": 2,
    "tie": 3
}

export const RoundActionType = {
    Bet: 0,
    Split: 1,
    Insurance: 2,
    DealerAction: 3,
    PlayerAction: 4,
    Result: 5
}

export const PlayerHandType = {
    OneHand: 0,
    TwoHands: 1
}

export const InsuranceType = {
    Yes: 0,
    No: 1
}

export const PlayerActionType = {
    Hit: 0,
    Stand: 1,
    Double:2
}

export const BetType = {
    Main: 0,
    Split: 1,
    Insurance: 2,
    Double: 3,
    PerfectPairs: 4,
    _21plus3: 5
}

export const OwnerTypes = {
    dealer: 0,
    player: 1,
    "0": "dealer",
    "1": "player"
}

export const ActiveAreaTypes = {
   none: 0,
   player: 1,
   banker: 2,
   0: "none",
   1: "player",
   2: "banker"
}

export const CardType = {
    2: "Two",
    3: "Three",
    4: "Four",
    5: "Five",
    6: "Six",
    7: "Seven",
    8: "Eight",
    9: "Nine",
    10: "Ten",
    11: "Jack",
    12: "Queen",
    13: "King",
    14: "Ace",
    15: "Joker1",
    16: "Joker2"
}

export const CardTypeMap = {
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "J",
    12: "Q",
    13: "K",
    14: "A",
    15: "Joker1",
    16: "Joker2"
}


export const decksCount = 8