import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_SELECT_DEALER,
    SET_SELECT_DEALER,
    SET_ERROR_MSG
} from "@actions/actionTypes";
import {PutResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";

function* selectDealer({code}) {
    const res =  yield call(PutResource, `/dealer`, {barCode: code});
    yield  put({
        type: SET_SELECT_DEALER
    })
    yield put({type: SET_ERROR_MSG, data: {type: 'info', messages: `successed`}});
}

function* dealerSaga() {
    yield takeLatest(REQUEST_SELECT_DEALER,safe(onError, selectDealer));

}

export default dealerSaga;