import React, {useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import {gameCancel, gameStart, gameResult, getGameIsActive, changeGameIsActive, checkGameIsActive} from "@actions/gameControl";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Space, Row, Col, Switch} from "antd";
import CardsList from "@components/CardsList/CardsList";
import {scanCard} from "@actions/cardAction";
import Timer from "@components/Timer/Timer";
import classNames from "classnames";
import {winnerTypes, playerTypes} from "@constants/Enums";
import CustomSelect from "@components/CustomSelect/customSelect";
import Settings from "@components/Settings";
import { PlayerHandType, RoundActionType, OwnerTypes } from "../../../constants/Enums";

function GameControl({
                         gameCancel,
                         gameStart,
                         gameResult,
                         getGameIsActive,
                         loading,
                         playerHands,
                         dealerHand,
                         scanCard,
                         timer,
                         winner,
                         changeGameIsActive,
                         isActive,
                         activeArea,
                         round,
                         isActiveResult,
                         checkGameIsActive,
                         intl,
                         openClosedCard
                     }) {
    const [online, setOnline] = useState(navigator.onLine);
    const timeOut = useRef([])

    useEffect(() => {
        function enable(e) {
            setOnline(true)
        }

        function disable(e) {
            setOnline(false)
        }

        window.addEventListener('online', enable);
        window.addEventListener('offline', disable);

        return () => {
            window.removeEventListener('online', enable);
            window.removeEventListener('offline', disable);
        }
    }, [])

    useEffect(() => {
        if (online) {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
            checkGameIsActive()
        }
    }, [online])

    useEffect(() => {
        if (!loading._check_game_is_active && online) {
            timeOut.current.push(setInterval(() => {
                checkGameIsActive()
            }, 2000))
        }
        return () => {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
        }
    }, [loading._check_game_is_active])


    const autoFocus = () => {
        window.focus();
        if (document.activeElement) {
            document.activeElement.blur();
        }
    }

    return (<div>
        <div className="titleArea">
            <div className="titleArea--title"><FormattedMessage id='game_control'/></div>
            <div className="titleArea--BtnGroup">
                <Settings />
                <div className="game-show-switch">
                    <span><FormattedMessage id="enable_game" /></span>
                     <Switch loading={loading._change_game_is_active}
                        onClick={() => changeGameIsActive(!isActive)}
                        // disabled={turn}
                        checked={isActive}/>
                </div>
               
            </div>
        </div>
        <div className="game-control">
            {isActive ?
                <>
                    <Row className="game-control-row" justify="space-between" align="center">
                        <Col span={10}
                             className={classNames("game-control-col banker-cards", {active: activeArea.owner == OwnerTypes.dealer})}>
                            {
                                winner === winnerTypes.banker || winner === winnerTypes.tie?
                                    <h2 className={classNames("winner", {draw: winner === winnerTypes.tie})}>
                                        <FormattedMessage id={winner === winnerTypes.tie ? "tie" : "winner"}/>
                                    </h2> : ""
                            }

                            <div className="title">Dealer</div>
                            {dealerHand && <CardsList cards={dealerHand.cards} isActive={true}/>}
                        </Col>
                        <div className="custom-control">
                            <Timer timer={timer}
                                   scanCard={scanCard}
                            />
                            {
                                round.actionType == RoundActionType.DealerAction && !isActiveResult && !openClosedCard?
                                    <CustomSelect autoFocus={autoFocus} disabledCustomSelect={loading._game_cancel}/>
                                    : ""
                            }

                        </div>
                        <Col span={10} className={classNames("game-control-col player-cards", {active: activeArea.owner == OwnerTypes.player})}>
                            {
                                winner === winnerTypes.player || winner === winnerTypes.tie ?
                                    <h2 className={classNames("winner", {draw: winner === winnerTypes.tie})}>
                                        <FormattedMessage id={winner === winnerTypes.tie ? "tie" : "winner"}/>
                                    </h2> : ""
                            }
                            <div className="title">Player</div>
                            {
                                playerHands && playerHands.map(hand => (
                                    <CardsList key={hand.handId} cards={hand.cards} isActive={true} />
                                ))
                            }
                            
                        </Col>
                    </Row>
                    <Row justify="space-between" align="center">
                        <Col span={8} className="control-buttons">
                            <Space>
                                {
                                    <>
                                        {
                                            !round.roundId && !isActiveResult ?
                                                <Button loading={loading._game_start}
                                                        disabled={loading._game_result || loading._game_start || loading._game_cancel}
                                                        className="miniBtn"
                                                        size="large"
                                                        onClick={gameStart}>
                                                    <FormattedMessage id='start'/>
                                                </Button>
                                                : ""
                                        }
                                        {
                                            isActiveResult ?
                                                <Button loading={loading._game_result}
                                                        disabled={loading._game_result || loading._game_start || loading._game_cancel}
                                                        className="miniBtn"
                                                        size="large"
                                                        onClick={gameResult}>
                                                    <FormattedMessage id='result'/>
                                                </Button>
                                                : ""
                                        }

                                    </>
                                }

                            </Space>
                        </Col>
                        <Col span={8} className="control-buttons">
                            <Space>
                                {
                                    !round.roundId  ? "" :
                                        <Button loading={loading._game_cancel}
                                                disabled={loading._game_result || loading._game_start || loading._game_cancel || !round.roundId}
                                                className="miniBtn"
                                                size="large"
                                                onClick={gameCancel}>
                                            <FormattedMessage id='cancel'/>
                                        </Button>
                                }

                            </Space>
                        </Col>
                    </Row>
                </> :
                <div className="message">
                    <FormattedMessage id="table_is_not_active"/>
                </div>}


        </div>


    </div>)
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        dealerHand: state.dealerHand,
        playerHands: state.playerHands,
        timer: state.timer,
        winner: state.winner,
        isActive: state.gameIsActive,
        activeArea: state.activeArea,
        round: state.round,
        isActiveResult: state.isActiveResult,
        openClosedCard: state.openClosedCard
    }
}

const mapDispatchToProps = {
    gameCancel,
    gameStart,
    gameResult,
    scanCard,
    getGameIsActive,
    changeGameIsActive,
    checkGameIsActive
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(GameControl))