import { OwnerTypes, RoundActionType } from "../../constants/Enums";
import {
    REQUEST_GAME_CANCEL,
    REQUEST_GAME_RESULT,
    REQUEST_GAME_START,
    REQUEST_GAME_INFO,
    REQUEST_CHANGE_GAME_IS_ACTIVE,
    SET_SERVER_TIME,
    REQUEST_GAME_IS_ACTIVE,
    CONNECT_TO_SOCKET,
    ADD_PLAYER_CARD,
    ADD_DEALER_CARD,
    CANCEL_ROUND,
    SET_ROUND_END,
    SET_ACTIVE_AREA,
    SET_RESULT,
    SET_TIMER,
    SUB_PINGRESPONSE,
    SET_ROUND_ACTION,
    SUB_GAMEINFO,
    SET_ROUND,
    SET_DEALER_HAND,
    SET_PLAYER_HANDS,
    SUB_ROUNDSTART,
    SUB_NEWCARD,
    SUB_ROUNDACTION,
    SUB_ROUNDEND,
    SUB_ROUNDCANCEL,
    SUB_OPENCARD,
    SUB_SPLIT,
    SUB_ACTIVEHAND,
    SUB_RESULT,
    SUB_OPEN_CLOSED_CARD,
    SET_OPEN_DEALER_CLOSED_CARD,
    REQUEST_CHECK_GAME_IS_ACTIVE
} from "./actionTypes";

export function  gameStart() {
    return dispatch => {
        dispatch({type: REQUEST_GAME_START})
    }
}
export function  gameResult() {
    return dispatch => {
        dispatch({type: REQUEST_GAME_RESULT})
    }
}

export function  gameCancel() {
    return dispatch => {
        dispatch({type: REQUEST_GAME_CANCEL})
    }
}

export function gameInfo() {
    return dispatch => {
        dispatch({type: REQUEST_GAME_INFO})
    }
}

export function changeGameIsActive(isActive) {
    return dispatch => {
        dispatch({type: REQUEST_CHANGE_GAME_IS_ACTIVE, isActive})
    }
}

export function getGameIsActive() {
    return dispatch => {
        dispatch({type: REQUEST_GAME_IS_ACTIVE})
    }
}

export function checkGameIsActive() {
    return dispatch => {
        dispatch({type: REQUEST_CHECK_GAME_IS_ACTIVE})
    }
}

export function connectToSocket() {
    return dispatch => {
        dispatch({
            type: CONNECT_TO_SOCKET
        })
    }
}

export function subscribePlayerAction() {
    return dispatch => {
        dispatch({
            eventOn: SUB_GAMEINFO,
            handle: (data) => {
                console.log("GAMEINFO", data)
                if (data.Round) {
                    dispatch({type: SET_ROUND, round: {
                            actionEndTime: data.Round.ActionEndTime,
                            actionStartTime: data.Round.ActionStartTime,
                            actionType: data.Round.ActionType,
                            roundId: data.Round.RoundId
                        }
                    });
                    dispatch({type: SET_DEALER_HAND, dealerHand: {
                            cards: data.Round.DealerHand.Cards,
                            handId: data.Round.DealerHand.HandId,
                            point1: data.Round.DealerHand.Point1,
                            point2: data.Round.DealerHand.Point2
                        }
                    });

                    let playerHands = data.Round.PlayerHands.map(playerHand => ({
                        cards: playerHand.Cards,
                        handId: playerHand.HandId,
                        point1: playerHand.Point1,
                        point2: playerHand.Point2
                    }))
                    console.log(playerHands,"playerHands")
                    dispatch({type: SET_PLAYER_HANDS, playerHands: playerHands});
                    dispatch({
                        type: SET_TIMER,
                        timer: {
                            timerStart: data.Round.ActionStartTime,
                            timerEnd: data.Round.ActionEndTime
                        }
                    });
                    if (data.Round.ActionType == RoundActionType.Result) {
                        dispatch({type: SET_RESULT, value: true});
                    }
                }
            }
        })
    }
}

export function subscribeRoundstart() {
    return dispatch => {
        dispatch({
            eventOn: SUB_ROUNDSTART,
            handle: (data) => {
                dispatch({
                    type: SET_ROUND,
                    round: {
                        actionEndTime: data.ActionEndTime,
                        actionStartTime: data.ActionStartTime,
                        actionType: data.ActionType,
                        roundId: data.RoundId,
                        turn: data.turn
                    }
                });
                dispatch({
                    type: SET_TIMER,
                    timer: {
                        timerStart: data.ActionStartTime,
                        timerEnd: data.ActionEndTime
                    }
                });
                dispatch({type: SET_OPEN_DEALER_CLOSED_CARD, isEnable: false});
                console.log("ROUNDSTART", data)
            }
        })
    }
}

export function subscribeNewcard() {
    return dispatch => {
        dispatch({
            eventOn: SUB_NEWCARD,
            handle: (data) => {
                let owner = data[0]?.Owner;
                if (owner == OwnerTypes.player) {
                    dispatch({type: ADD_PLAYER_CARD, hand: data});
                } else if (owner == OwnerTypes.dealer) {
                    dispatch({type: ADD_DEALER_CARD, hand: data});
                    dispatch({type: SET_OPEN_DEALER_CLOSED_CARD, isEnable: false});
                } else {
                    console.error("unexpected owner", data)
                }
                console.log("NEWCARD", data)
            }
        })
    }
}

export function subscribeRoundaction() {
    return dispatch => {
        dispatch({
            eventOn: SUB_ROUNDACTION,
            handle: (data) => {
                console.log("ROUNDACTION", data)
                dispatch({type: SET_ROUND_ACTION, value: data});
                dispatch({
                    type: SET_TIMER,
                    timer: {
                        timerStart: data.ActionStartTime,
                        timerEnd: data.ActionEndTime
                    }
                });
            }
        })
    }
}

export function subscribeRoundend() {
    return dispatch => {
        dispatch({
            eventOn: SUB_ROUNDEND,
            handle: (data) => {
                console.log("ROUNDEND", data)
                dispatch({type: SET_ROUND_END});
                dispatch({type: SET_OPEN_DEALER_CLOSED_CARD, isEnable: false});
            }
        })
    }
}

export function subscribeRoundcancel() {
    return (dispatch, getStore) => {
        dispatch({
            eventOn: SUB_ROUNDCANCEL,
            handle: (data) => {
                const { round } = getStore();
                if (round.roundId == data.RoundId) {
                    dispatch({type: CANCEL_ROUND});
                }
                console.log("ROUNDCANCEL", data)
            }
        })
    }
}

export function subscribeOpencard() {
    return dispatch => {
        dispatch({
            eventOn: SUB_OPENCARD,
            handle: (data) => {
                console.log("OPENCARD", data)
                dispatch({type: SET_ACTIVE_AREA, activeArea: {
                    owner: data.Owner,
                    handId: data.HandId
                }});
            }
        })
    }
}

export function subscribeSplit() {
    return dispatch => {
        dispatch({
            eventOn: SUB_SPLIT,
            handle: (data) => {
                console.log("SPLIT", data)
            }
        })
    }
}

export function subscribeActivehand() {
    return dispatch => {
        dispatch({
            eventOn: SUB_ACTIVEHAND,
            handle: (data) => {
                console.log("ACTIVEHAND", data)
            }
        })
    }
}

export function subscribeOpenClosedCard() {
    return dispatch => {
        dispatch({
            eventOn: SUB_OPEN_CLOSED_CARD,
            handle: (data) => {
                console.log("OPEN CLOSED CARD", data);
                dispatch({type: SET_OPEN_DEALER_CLOSED_CARD, isEnable: true});
            }
        })
    }
}


export function subscribeResult() {
    return dispatch => {
        dispatch({
            eventOn: SUB_RESULT,
            handle: (data) => {
                console.log("RESULT", data)
                dispatch({type: SET_RESULT, value: true});
            }
        })
    }
}


export function subscribePingResponse() {
    return dispatch => {
        dispatch({
            eventOn: SUB_PINGRESPONSE,
            handle: (data) => {
                console.log("PingResponse", data)
                dispatch({type: SET_SERVER_TIME, time: data.Time});
            }
        })
    }
}
