export function getLiveTime(intl) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    month = month < 10 ? '0'+month : month;
    let day = currentDate.getDate();
    day = day < 10 ? '0'+day : day;
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let seconds =  currentDate.getSeconds()
    seconds = seconds < 10 ? '0'+seconds : seconds;

    const ampm = hours >= 12 ? intl.formatMessage({id:"pm"}) : intl.formatMessage({id:"am"});
    hours = hours % 12;
    hours = hours ? hours : 12;

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`
}