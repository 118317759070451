import initialStore from "../initialStore";
import {
    SET_ROUND,
    CANCEL_ROUND,
    SET_ROUND_ACTION,
    SET_ROUND_END
} from "@actions/actionTypes";

export default function roundReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_ROUND:
            return action.round;
        case CANCEL_ROUND:
        case SET_ROUND_END:
            return initialStore.emptyObj;
        case SET_ROUND_ACTION:
            return {
                ...state,
                actionEndTime: action.value.ActionEndTime,
                actionStartTime: action.value.ActionStartTime,
                actionType: action.value.Type
            }
        default:
            return state;
    }
}
