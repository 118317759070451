import {
    REQUEST_CHANGE_AUTO_START,
    REQUEST_SETTINGS,
    REQUEST_CHANGE_AUTO_RESULT
} from "./actionTypes";

export function getSettings() {
    return dispatch => {
        dispatch({type: REQUEST_SETTINGS});
    }
}
export function changeAutoStart(data) {
    return dispatch => {
        dispatch({type: REQUEST_CHANGE_AUTO_START, data});
    }
}

export function changeAutoResult(data) {
    return dispatch => {
        dispatch({type: REQUEST_CHANGE_AUTO_RESULT, data});
    }
}