import {CONNECT_TO_SOCKET} from "@actions/actionTypes";

export default function socketMiddleware(socket) {
    return ({dispatch}) => next => (action) => {
        if (typeof action === 'function') return next(action);

        if (action.type === CONNECT_TO_SOCKET) {
            socket.connect()
            return;
        }

        const {eventOn, eventEmit, handle, emitData} = action;

        if (eventOn && typeof eventOn === "string") {
            return socket.on(eventOn, handle);
        } else if (eventEmit && typeof eventEmit === "string") {
             socket.emit(eventEmit, emitData);
        }
        
        return next(action);
    };
}
