import React, {useState} from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import cardBg from "@assets/img/cardBg.svg";
import CustomSelectModal from "@components/CustomSelectModal/CustomSelectModal";
import { CardTypeMap, CardSuit } from "@constants/Enums";

function CardListItem({card, cardList, suspended, key, disabled, openClosedCard}) {
    return (
        <li className={classNames("cards-list-item", {disabled})} key={key}>
            <div className={classNames("flip-card", {active: card.Type, animate: openClosedCard && !card.Type})} >
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <img src={cardBg} alt={cardBg} />
                    </div>
                    <div className="flip-card-back">
                        <i className={`cardIcon icon-${card.Type && CardTypeMap[card.Type]}${card.Suit && CardSuit[card.Suit]}`}/>
                    </div>
                </div>
            </div>
        </li>
    )
}
const mapStateToProps = (state) => {
    return {
        suspended: state.suspended,
        openClosedCard: state.openClosedCard
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(CardListItem)