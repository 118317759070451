// worker
export const SET_SERVER_TIME = "SET_SERVER_TIME";
export const SET_SUB_TICKER_WORKER = "SET_SUB_TICKER_WORKER";
export const SET_UN_SUB_TICKER_WORKER = "SET_UN_SUB_TICKER_WORKER";
export const GET_SERVER_TIME = "GET_SERVER_TIME";

// SET
export const SET_LANG_LIST = "SET_LANG_LIST";

export const SET_START_PATH = "SET_START_PATH";


// gameControl
export const REQUEST_GAME_START = "REQUEST_GAME_START";
export const REQUEST_GAME_RESULT = "REQUEST_GAME_RESULT";
export const REQUEST_GAME_CANCEL = "REQUEST_GAME_CANCEL";
export const REQUEST_GAME_INFO = "REQUEST_GAME_INFO";
export const SET_GAME_RESULT = "SET_GAME_RESULT";
export const SET_GAME_CANCEL = "SET_GAME_CANCEL";
export const SET_GAME_INFO = "SET_GAME_INFO";
export const SET_GAME_START = "SET_GAME_START";
export const REQUEST_CHANGE_GAME_IS_ACTIVE = "REQUEST_CHANGE_GAME_IS_ACTIVE";
export const REQUEST_GAME_IS_ACTIVE = "REQUEST_GAME_IS_ACTIVE";
export const SET_GAME_IS_ACTIVE = "SET_GAME_IS_ACTIVE";
export const SET_CHECK_GAME_IS_ACTIVE = "SET_CHECK_GAME_IS_ACTIVE";
export const REQUEST_CHECK_GAME_IS_ACTIVE = "REQUEST_CHECK_GAME_IS_ACTIVE";
export const SET_CHANGE_GAME_IS_ACTIVE = "SET_CHANGE_GAME_IS_ACTIVE";

//errors
export const  SET_ERROR_MSG = "SET_ERROR_MSG";
export const  RESET_ERROR_MSG = "RESET_ERROR_MSG";
export const CLEAR_RESPONSE_ERROR = "CLEAR_RESPONSE_ERROR";
export const CHANGE_RESPONSE_ERROR = "CHANGE_RESPONSE_ERROR";

//loading

export const CHANGE_LOADING = 'CHANGE_LOADING';


// card
export const REQUEST_CARD = "REQUEST_CARD";
export const SET_CARD = "SET_CARD";
export const SET_CARDS = "SET_CARDS";
export const SET_OPEN_DEALER_CLOSED_CARD = "SET_OPEN_DEALER_CLOSED_CARD";

export const SET_RESULT = "SET_RESULT";

//timer
export const SET_TIMER = "SET_TIMER";

// winner
export const SET_WINNER = "SET_WINNER";

//active area
export const SET_ACTIVE_AREA = "SET_ACTIVE_AREA";

//round
export const SET_ROUND = "SET_ROUND";
export const CANCEL_ROUND = "CANCEL_ROUND";
export const SET_ROUND_ACTION = "SET_ROUND_ACTION";
export const SET_ROUND_END = "SET_ROUND_END";

//settings

export const REQUEST_CHANGE_AUTO_START = "REQUEST_CHANGE_AUTO_START";
export const REQUEST_SETTINGS = "REQUEST_SETTINGS";
export const SET_SETTINGS = "SET_SETTINGS";
export const REQUEST_CHANGE_AUTO_RESULT = "REQUEST_CHANGE_AUTO_RESULT";
export const SET_CHANGE_AUTO_START = "SET_CHANGE_AUTO_START";
export const SET_CHANGE_AUTO_RESULT = "SET_CHANGE_AUTO_RESULT";

//socket actions 
export const SET_SOCKET_CONNECTIONS = "SET_SOCKET_CONNECTIONS";
export const CONNECT_TO_SOCKET ="CONNECT_TO_SOCKET";

export const PING_EVENT = "0";
export const PLACEBET_EVENT = "1";
export const PLAYERACTION_EVENT = "2";
export const SPLITACTION_EVENT = "3";
export const INSURANCEACTION_EVENT = "4";



export const SUB_ERROR = "0";
export const SUB_PINGRESPONSE = "1";
export const SUB_GAMEINFO = "2";
export const SUB_ROUNDSTART = "3";
export const SUB_BET = "4";
export const SUB_NEWCARD = "5";
export const SUB_ROUNDACTION = "6";
export const SUB_ROUNDEND = "7";
export const SUB_ROUNDCANCEL = "8";
export const SUB_BALANCEUPDATE = "9";
export const SUB_OPENCARD = "10";
export const SUB_SPLIT = "11";
export const SUB_ACTIVEHAND = "12";
export const SUB_RESULT = "13";
export const SUB_OPEN_CLOSED_CARD = "14";


//card
export const SET_DEALER_HAND = "SET_DEALER_HAND";
export const SET_PLAYER_HANDS = "SET_PLAYER_HANDS";

export const ADD_PLAYER_CARD = "ADD_PLAYER_CARD";
export const ADD_DEALER_CARD = "ADD_DEALER_CARD";

//dealer
export const REQUEST_SELECT_DEALER = "REQUEST_SELECT_DEALER"
export const SET_SELECT_DEALER = "SET_SELECT_DEALER"

// public enum NotificationType
// {
//     Error,
//     PingResponse,
//     GameInfo,
//     RoundStart,
//     Bet,
//     HandUpdate,
//     RoundAction,
//     RoundEnd,
//     RoundCancel,
//     BalanceUpdate,
//     OpenCard,
//     Split,
//     ActiveHand,
//     Result,
//     OpenClosedCard
// }