const host = process.env.BLACKJACK_DEALER_ADMIN_SOCKET_PAT;

function SocketClient() {
    this.client = null;
    let pingTimeOut = 0;
    let subscribers = [];
    let openSubscribers = [];
    let closeSubscribers = [];
    let disconnectSubscribers =[];
    let isConnected = false;
    let reconnectTimerId;
    let session = "";
    const that = this;
    window.addEventListener('load', function() {

        function updateOnlineStatus(event) {
            if (navigator.onLine) {
                clearTimeout(reconnectTimerId);
                that.connect(session);
            } else {
                clearTimeout(pingTimeOut)
                that.client.close();
                isConnected = false;
            }
        }

        window.addEventListener('online',  updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
    });

    this.message = function (event) {
        let message = JSON.parse(event.data);
        subscribers.forEach((subscriber) => {
            if (subscriber.eventName == message.Type) {
                subscriber.callBack(message.Data);
            }
        })
    }

    this.connect =  function () {
        try {
            this.client = new WebSocket(host);

            this.client.addEventListener('message', this.message);
            this.client.addEventListener('open', this.open);
            this.client.addEventListener('close', this.close.bind(this));
            this.client.addEventListener('error', this.onError.bind(this));

        } catch (e) {
            console.error(e)
        }
        return this;

    }

    this.on = function (eventName, callBack) {
        subscribers.push({
            eventName: eventName,
            callBack: callBack
        })
    }

    this.onOpen = function(f) {
        openSubscribers.push(f);
    }

    this.open = function(data) {
        isConnected = true;
        openSubscribers.forEach(f => {
            f(data);
        })
    }

    this.onClose = function(f) {
        closeSubscribers.push(f);
    }

    this.onConnectEnd =  function (f) {
        disconnectSubscribers.push(f)
    }

    this.close = function(data, reason) {
        isConnected = false;
        console.log(data)
        if (data.code === 1003 || data.code === 1008) {
            clearTimeout(reconnectTimerId)
            disconnectSubscribers.forEach(f => f(data.code))
        } else {
            reconnectTimerId = setTimeout(() => {
                this.connect(session);
            }, 5000);
            closeSubscribers.forEach(f => {
                f(data);
            })
        }


    }

    this.onError = function (err) {
        console.error(err, this);
    }

    this.off = function (eventName, callBack) {
        subscribers = subscribers.filter(subscriber => subscriber.eventName != eventName && callBack !=  subscriber.callBack);
    }

    this.pingSocket =  function () {
        try {
            if (!this.client) return;
            if (this.client.readyState !== 1) return;
            this.client.send('{"Type": 0}');
            pingTimeOut = setTimeout(this.pingSocket.bind(this),5000);
        } catch (e) {
            console.error(e)
        }

    }

    this.emit = function (eventName, data) {
        try {
            let message = {Type: +eventName};
            if (data) {
                message.Data = data
            }
            this.client.send(JSON.stringify(message));
        } catch (e) {
            console.error(e)
        }

    }
}

export default function () {
    return new SocketClient()
}
