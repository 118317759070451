import {combineReducers} from "redux";
import settings from "./settingsReducer";
import langList from "./langListReducer";
import error from "./errorReducer";
import loading from "./loadingReducer"
import responseErrors from "./responseErrorsReducer";
import path from "./pathReducer";
import timer from "./timerReducer"
import winner from "./winnerReducer"
import gameIsActive from "./gameIsActiveReducer"
import activeArea from "./activeAreaReducer";
import globalInfo from "./globalInfoRducer";
import dealerHand from "./dealerHandReducer";
import playerHands from "./playerHandsReducer";
import round from "./roundReducer";
import isActiveResult from "./isActiveResultReducer";
import openClosedCard from "./openClosedCardReducer";


const rootReducer = combineReducers({
    langList,
    error,
    loading,
    responseErrors,
    path,
    settings,
    timer,
    winner,
    gameIsActive,
    activeArea,
    globalInfo,
    dealerHand,
    playerHands,
    round,
    isActiveResult,
    openClosedCard
});

export default rootReducer;
