import initialStore from "../initialStore";
import { SET_OPEN_DEALER_CLOSED_CARD } from "@actions/actionTypes";

export default function openClosedCardReducer(state = false, action) {
    switch (action.type) {
        case SET_OPEN_DEALER_CLOSED_CARD:
            return action.isEnable;
        default:
            return state;
    }
}
