export default [
    {
        "ID": 1,
        "CardName": "A♥",
        "Barcode": "AHH",
        "Number": 1,
        "Code": "1A",
        "CName": "Ace of Hearts",
        "Deck": 1.0,
        "CId": 14
    },
    {
        "ID": 2,
        "CardName": "A♠",
        "Barcode": "ASS",
        "Number": 1,
        "Code": "1B",
        "CName": "Ace of Spades",
        "Deck": 1.0,
        "CId": 1
    },
    {
        "ID": 3,
        "CardName": "A♣",
        "Barcode": "ACC",
        "Number": 1,
        "Code": "1C",
        "CName": "Ace of Clubs",
        "Deck": 1.0,
        "CId": 40
    },
    {
        "ID": 4,
        "CardName": "A♦",
        "Barcode": "ADD",
        "Number": 1,
        "Code": "1D",
        "CName": "Ace of Diamonds",
        "Deck": 1.0,
        "CId": 27
    },
    {
        "ID": 5,
        "CardName": "2♥",
        "Barcode": "2HH",
        "Number": 2,
        "Code": "1E",
        "CName": "Two of Hearts",
        "Deck": 1.0,
        "CId": 15
    },
    {
        "ID": 6,
        "CardName": "2♠",
        "Barcode": "2SS",
        "Number": 2,
        "Code": "1F",
        "CName": "Two of Spades",
        "Deck": 1.0,
        "CId": 2
    },
    {
        "ID": 7,
        "CardName": "2♣",
        "Barcode": "2CC",
        "Number": 2,
        "Code": "1G",
        "CName": "Two of Clubs",
        "Deck": 1.0,
        "CId": 41
    },
    {
        "ID": 8,
        "CardName": "2♦",
        "Barcode": "2DD",
        "Number": 2,
        "Code": "1H",
        "CName": "Two of Diamonds",
        "Deck": 1.0,
        "CId": 28
    },
    {
        "ID": 9,
        "CardName": "3♥",
        "Barcode": "3HH",
        "Number": 3,
        "Code": "1I",
        "CName": "Three of Hearts",
        "Deck": 1.0,
        "CId": 16
    },
    {
        "ID": 10,
        "CardName": "3♠",
        "Barcode": "3SS",
        "Number": 3,
        "Code": "1J",
        "CName": "Three of Spades",
        "Deck": 1.0,
        "CId": 3
    },
    {
        "ID": 11,
        "CardName": "3♣",
        "Barcode": "3CC",
        "Number": 3,
        "Code": "1K",
        "CName": "Three of Clubs",
        "Deck": 1.0,
        "CId": 42
    },
    {
        "ID": 12,
        "CardName": "3♦",
        "Barcode": "3DD",
        "Number": 3,
        "Code": "1L",
        "CName": "Three of Diamonds",
        "Deck": 1.0,
        "CId": 29
    },
    {
        "ID": 13,
        "CardName": "4♥",
        "Barcode": "4HH",
        "Number": 4,
        "Code": "1M",
        "CName": "Four of Hearts",
        "Deck": 1.0,
        "CId": 17
    },
    {
        "ID": 14,
        "CardName": "4♠",
        "Barcode": "4SS",
        "Number": 4,
        "Code": "1N",
        "CName": "Four of Spades",
        "Deck": 1.0,
        "CId": 4
    },
    {
        "ID": 15,
        "CardName": "4♣",
        "Barcode": "4CC",
        "Number": 4,
        "Code": "1O",
        "CName": "Four of Clubs",
        "Deck": 1.0,
        "CId": 43
    },
    {
        "ID": 16,
        "CardName": "4♦",
        "Barcode": "4DD",
        "Number": 4,
        "Code": "1P",
        "CName": "Four of Diamonds",
        "Deck": 1.0,
        "CId": 30
    },
    {
        "ID": 17,
        "CardName": "5♥",
        "Barcode": "5HH",
        "Number": 5,
        "Code": "1Q",
        "CName": "Five of Hearts",
        "Deck": 1.0,
        "CId": 18
    },
    {
        "ID": 18,
        "CardName": "5♠",
        "Barcode": "5SS",
        "Number": 5,
        "Code": "1R",
        "CName": "Five of Spades",
        "Deck": 1.0,
        "CId": 5
    },
    {
        "ID": 19,
        "CardName": "5♣",
        "Barcode": "5CC",
        "Number": 5,
        "Code": "1S",
        "CName": "Five of Clubs",
        "Deck": 1.0,
        "CId": 44
    },
    {
        "ID": 20,
        "CardName": "5♦",
        "Barcode": "5DD",
        "Number": 5,
        "Code": "1T",
        "CName": "Five of Diamonds",
        "Deck": 1.0,
        "CId": 31
    },
    {
        "ID": 21,
        "CardName": "6♥",
        "Barcode": "6HH",
        "Number": 6,
        "Code": "1U",
        "CName": "Six of Hearts",
        "Deck": 1.0,
        "CId": 19
    },
    {
        "ID": 22,
        "CardName": "6♠",
        "Barcode": "6SS",
        "Number": 6,
        "Code": "1V",
        "CName": "Six of Spades",
        "Deck": 1.0,
        "CId": 6
    },
    {
        "ID": 23,
        "CardName": "6♣",
        "Barcode": "6CC",
        "Number": 6,
        "Code": "1W",
        "CName": "Six of Clubs",
        "Deck": 1.0,
        "CId": 45
    },
    {
        "ID": 24,
        "CardName": "6♦",
        "Barcode": "6DD",
        "Number": 6,
        "Code": "1X",
        "CName": "Six of Diamonds",
        "Deck": 1.0,
        "CId": 32
    },
    {
        "ID": 25,
        "CardName": "7♥",
        "Barcode": "7HH",
        "Number": 7,
        "Code": "1Y",
        "CName": "Seven of Hearts",
        "Deck": 1.0,
        "CId": 20
    },
    {
        "ID": 26,
        "CardName": "7♠",
        "Barcode": "7SS",
        "Number": 7,
        "Code": "1Z",
        "CName": "Seven of Spades",
        "Deck": 1.0,
        "CId": 7
    },
    {
        "ID": 27,
        "CardName": "7♣",
        "Barcode": "7CC",
        "Number": 7,
        "Code": "2A",
        "CName": "Seven of Clubs",
        "Deck": 1.0,
        "CId": 46
    },
    {
        "ID": 28,
        "CardName": "7♦",
        "Barcode": "7DD",
        "Number": 7,
        "Code": "2B",
        "CName": "Seven of Diamonds",
        "Deck": 1.0,
        "CId": 33
    },
    {
        "ID": 29,
        "CardName": "8♥",
        "Barcode": "8HH",
        "Number": 8,
        "Code": "2C",
        "CName": "Eight of Hearts",
        "Deck": 1.0,
        "CId": 21
    },
    {
        "ID": 30,
        "CardName": "8♠",
        "Barcode": "8SS",
        "Number": 8,
        "Code": "2D",
        "CName": "Eight of Spades",
        "Deck": 1.0,
        "CId": 8
    },
    {
        "ID": 31,
        "CardName": "8♣",
        "Barcode": "8CC",
        "Number": 8,
        "Code": "2E",
        "CName": "Eight of Clubs",
        "Deck": 1.0,
        "CId": 47
    },
    {
        "ID": 32,
        "CardName": "8♦",
        "Barcode": "8DD",
        "Number": 8,
        "Code": "2F",
        "CName": "Eight of Diamonds",
        "Deck": 1.0,
        "CId": 34
    },
    {
        "ID": 33,
        "CardName": "9♥",
        "Barcode": "9HH",
        "Number": 9,
        "Code": "2G",
        "CName": "Nine of Hearts",
        "Deck": 1.0,
        "CId": 22
    },
    {
        "ID": 34,
        "CardName": "9♠",
        "Barcode": "9SS",
        "Number": 9,
        "Code": "2H",
        "CName": "Nine of Spades",
        "Deck": 1.0,
        "CId": 9
    },
    {
        "ID": 35,
        "CardName": "9♣",
        "Barcode": "9CC",
        "Number": 9,
        "Code": "2I",
        "CName": "Nine of Clubs",
        "Deck": 1.0,
        "CId": 48
    },
    {
        "ID": 36,
        "CardName": "9♦",
        "Barcode": "9DD",
        "Number": 9,
        "Code": "2J",
        "CName": "Nine of Diamonds",
        "Deck": 1.0,
        "CId": 35
    },
    {
        "ID": 37,
        "CardName": "10♥",
        "Barcode": "10HH",
        "Number": 10,
        "Code": "2K",
        "CName": "Ten of Hearts",
        "Deck": 1.0,
        "CId": 23
    },
    {
        "ID": 38,
        "CardName": "10♠",
        "Barcode": "10SS",
        "Number": 10,
        "Code": "2L",
        "CName": "Ten of Spades",
        "Deck": 1.0,
        "CId": 10
    },
    {
        "ID": 39,
        "CardName": "10♣",
        "Barcode": "10CC",
        "Number": 10,
        "Code": "2M",
        "CName": "Ten of Clubs",
        "Deck": 1.0,
        "CId": 49
    },
    {
        "ID": 40,
        "CardName": "10♦",
        "Barcode": "10DD",
        "Number": 10,
        "Code": "2N",
        "CName": "Ten of Diamonds",
        "Deck": 1.0,
        "CId": 36
    },
    {
        "ID": 41,
        "CardName": "J♥",
        "Barcode": "JHH",
        "Number": 11,
        "Code": "2O",
        "CName": "Jack of Hearts",
        "Deck": 1.0,
        "CId": 24
    },
    {
        "ID": 42,
        "CardName": "J♠",
        "Barcode": "JSS",
        "Number": 11,
        "Code": "2P",
        "CName": "Jack of Spades",
        "Deck": 1.0,
        "CId": 11
    },
    {
        "ID": 43,
        "CardName": "J♣",
        "Barcode": "JCC",
        "Number": 11,
        "Code": "2Q",
        "CName": "Jack of Clubs",
        "Deck": 1.0,
        "CId": 50
    },
    {
        "ID": 44,
        "CardName": "J♦",
        "Barcode": "JDD",
        "Number": 11,
        "Code": "2R",
        "CName": "Jack of Diamonds",
        "Deck": 1.0,
        "CId": 37
    },
    {
        "ID": 45,
        "CardName": "Q♥",
        "Barcode": "QHH",
        "Number": 12,
        "Code": "2S",
        "CName": "Queen of Hearts",
        "Deck": 1.0,
        "CId": 25
    },
    {
        "ID": 46,
        "CardName": "Q♠",
        "Barcode": "QSS",
        "Number": 12,
        "Code": "2T",
        "CName": "Queen of Spades",
        "Deck": 1.0,
        "CId": 12
    },
    {
        "ID": 47,
        "CardName": "Q♣",
        "Barcode": "QCC",
        "Number": 12,
        "Code": "2U",
        "CName": "Queen of Clubs",
        "Deck": 1.0,
        "CId": 51
    },
    {
        "ID": 48,
        "CardName": "Q♦",
        "Barcode": "QDD",
        "Number": 12,
        "Code": "2V",
        "CName": "Queen of Diamonds",
        "Deck": 1.0,
        "CId": 38
    },
    {
        "ID": 49,
        "CardName": "K♥",
        "Barcode": "KHH",
        "Number": 13,
        "Code": "2W",
        "CName": "King of Hearts",
        "Deck": 1.0,
        "CId": 26
    },
    {
        "ID": 50,
        "CardName": "K♠",
        "Barcode": "KSS",
        "Number": 13,
        "Code": "2X",
        "CName": "King of Spades",
        "Deck": 1.0,
        "CId": 13
    },
    {
        "ID": 51,
        "CardName": "K♣",
        "Barcode": "KCC",
        "Number": 13,
        "Code": "2Y",
        "CName": "King of Clubs",
        "Deck": 1.0,
        "CId": 52
    },
    {
        "ID": 52,
        "CardName": "K♦",
        "Barcode": "KDD",
        "Number": 13,
        "Code": "2Z",
        "CName": "King of Diamonds",
        "Deck": 1.0,
        "CId": 39
    },
    {
        "ID": 54,
        "CardName": "A♥",
        "Barcode": "AHH",
        "Number": 1,
        "Code": "3A",
        "CName": "Ace of Hearts",
        "Deck": 2.0,
        "CId": 14
    },
    {
        "ID": 55,
        "CardName": "A♠",
        "Barcode": "ASS",
        "Number": 1,
        "Code": "3B",
        "CName": "Ace of Spades",
        "Deck": 2.0,
        "CId": 1
    },
    {
        "ID": 56,
        "CardName": "A♣",
        "Barcode": "ACC",
        "Number": 1,
        "Code": "3C",
        "CName": "Ace of Clubs",
        "Deck": 2.0,
        "CId": 40
    },
    {
        "ID": 57,
        "CardName": "A♦",
        "Barcode": "ADD",
        "Number": 1,
        "Code": "3D",
        "CName": "Ace of Diamonds",
        "Deck": 2.0,
        "CId": 27
    },
    {
        "ID": 58,
        "CardName": "2♥",
        "Barcode": "2HH",
        "Number": 2,
        "Code": "3E",
        "CName": "Two of Hearts",
        "Deck": 2.0,
        "CId": 15
    },
    {
        "ID": 59,
        "CardName": "2♠",
        "Barcode": "2SS",
        "Number": 2,
        "Code": "3F",
        "CName": "Two of Spades",
        "Deck": 2.0,
        "CId": 2
    },
    {
        "ID": 60,
        "CardName": "2♣",
        "Barcode": "2CC",
        "Number": 2,
        "Code": "3G",
        "CName": "Two of Clubs",
        "Deck": 2.0,
        "CId": 41
    },
    {
        "ID": 61,
        "CardName": "2♦",
        "Barcode": "2DD",
        "Number": 2,
        "Code": "3H",
        "CName": "Two of Diamonds",
        "Deck": 2.0,
        "CId": 28
    },
    {
        "ID": 62,
        "CardName": "3♥",
        "Barcode": "3HH",
        "Number": 3,
        "Code": "3I",
        "CName": "Three of Hearts",
        "Deck": 2.0,
        "CId": 16
    },
    {
        "ID": 63,
        "CardName": "3♠",
        "Barcode": "3SS",
        "Number": 3,
        "Code": "3J",
        "CName": "Three of Spades",
        "Deck": 2.0,
        "CId": 3
    },
    {
        "ID": 64,
        "CardName": "3♣",
        "Barcode": "3CC",
        "Number": 3,
        "Code": "3K",
        "CName": "Three of Clubs",
        "Deck": 2.0,
        "CId": 42
    },
    {
        "ID": 65,
        "CardName": "3♦",
        "Barcode": "3DD",
        "Number": 3,
        "Code": "3L",
        "CName": "Three of Diamonds",
        "Deck": 2.0,
        "CId": 29
    },
    {
        "ID": 66,
        "CardName": "4♥",
        "Barcode": "4HH",
        "Number": 4,
        "Code": "3M",
        "CName": "Four of Hearts",
        "Deck": 2.0,
        "CId": 17
    },
    {
        "ID": 67,
        "CardName": "4♠",
        "Barcode": "4SS",
        "Number": 4,
        "Code": "3N",
        "CName": "Four of Spades",
        "Deck": 2.0,
        "CId": 4
    },
    {
        "ID": 68,
        "CardName": "4♣",
        "Barcode": "4CC",
        "Number": 4,
        "Code": "3O",
        "CName": "Four of Clubs",
        "Deck": 2.0,
        "CId": 43
    },
    {
        "ID": 69,
        "CardName": "4♦",
        "Barcode": "4DD",
        "Number": 4,
        "Code": "3P",
        "CName": "Four of Diamonds",
        "Deck": 2.0,
        "CId": 30
    },
    {
        "ID": 70,
        "CardName": "5♥",
        "Barcode": "5HH",
        "Number": 5,
        "Code": "3Q",
        "CName": "Five of Hearts",
        "Deck": 2.0,
        "CId": 18
    },
    {
        "ID": 71,
        "CardName": "5♠",
        "Barcode": "5SS",
        "Number": 5,
        "Code": "3R",
        "CName": "Five of Spades",
        "Deck": 2.0,
        "CId": 5
    },
    {
        "ID": 72,
        "CardName": "5♣",
        "Barcode": "5CC",
        "Number": 5,
        "Code": "3S",
        "CName": "Five of Clubs",
        "Deck": 2.0,
        "CId": 44
    },
    {
        "ID": 73,
        "CardName": "5♦",
        "Barcode": "5DD",
        "Number": 5,
        "Code": "3T",
        "CName": "Five of Diamonds",
        "Deck": 2.0,
        "CId": 31
    },
    {
        "ID": 74,
        "CardName": "6♥",
        "Barcode": "6HH",
        "Number": 6,
        "Code": "3U",
        "CName": "Six of Hearts",
        "Deck": 2.0,
        "CId": 19
    },
    {
        "ID": 75,
        "CardName": "6♠",
        "Barcode": "6SS",
        "Number": 6,
        "Code": "3V",
        "CName": "Six of Spades",
        "Deck": 2.0,
        "CId": 6
    },
    {
        "ID": 76,
        "CardName": "6♣",
        "Barcode": "6CC",
        "Number": 6,
        "Code": "3W",
        "CName": "Six of Clubs",
        "Deck": 2.0,
        "CId": 45
    },
    {
        "ID": 77,
        "CardName": "6♦",
        "Barcode": "6DD",
        "Number": 6,
        "Code": "3X",
        "CName": "Six of Diamonds",
        "Deck": 2.0,
        "CId": 32
    },
    {
        "ID": 78,
        "CardName": "7♥",
        "Barcode": "7HH",
        "Number": 7,
        "Code": "3Y",
        "CName": "Seven of Hearts",
        "Deck": 2.0,
        "CId": 20
    },
    {
        "ID": 79,
        "CardName": "7♠",
        "Barcode": "7SS",
        "Number": 7,
        "Code": "3Z",
        "CName": "Seven of Spades",
        "Deck": 2.0,
        "CId": 7
    },
    {
        "ID": 80,
        "CardName": "7♣",
        "Barcode": "7CC",
        "Number": 7,
        "Code": "4A",
        "CName": "Seven of Clubs",
        "Deck": 2.0,
        "CId": 46
    },
    {
        "ID": 81,
        "CardName": "7♦",
        "Barcode": "7DD",
        "Number": 7,
        "Code": "4B",
        "CName": "Seven of Diamonds",
        "Deck": 2.0,
        "CId": 33
    },
    {
        "ID": 82,
        "CardName": "8♥",
        "Barcode": "8HH",
        "Number": 8,
        "Code": "4C",
        "CName": "Eight of Hearts",
        "Deck": 2.0,
        "CId": 21
    },
    {
        "ID": 83,
        "CardName": "8♠",
        "Barcode": "8SS",
        "Number": 8,
        "Code": "4D",
        "CName": "Eight of Spades",
        "Deck": 2.0,
        "CId": 8
    },
    {
        "ID": 84,
        "CardName": "8♣",
        "Barcode": "8CC",
        "Number": 8,
        "Code": "4E",
        "CName": "Eight of Clubs",
        "Deck": 2.0,
        "CId": 47
    },
    {
        "ID": 85,
        "CardName": "8♦",
        "Barcode": "8DD",
        "Number": 8,
        "Code": "4F",
        "CName": "Eight of Diamonds",
        "Deck": 2.0,
        "CId": 34
    },
    {
        "ID": 86,
        "CardName": "9♥",
        "Barcode": "9HH",
        "Number": 9,
        "Code": "4G",
        "CName": "Nine of Hearts",
        "Deck": 2.0,
        "CId": 22
    },
    {
        "ID": 87,
        "CardName": "9♠",
        "Barcode": "9SS",
        "Number": 9,
        "Code": "4H",
        "CName": "Nine of Spades",
        "Deck": 2.0,
        "CId": 9
    },
    {
        "ID": 88,
        "CardName": "9♣",
        "Barcode": "9CC",
        "Number": 9,
        "Code": "4I",
        "CName": "Nine of Clubs",
        "Deck": 2.0,
        "CId": 48
    },
    {
        "ID": 89,
        "CardName": "9♦",
        "Barcode": "9DD",
        "Number": 9,
        "Code": "4J",
        "CName": "Nine of Diamonds",
        "Deck": 2.0,
        "CId": 35
    },
    {
        "ID": 90,
        "CardName": "10♥",
        "Barcode": "10HH",
        "Number": 10,
        "Code": "4K",
        "CName": "Ten of Hearts",
        "Deck": 2.0,
        "CId": 23
    },
    {
        "ID": 91,
        "CardName": "10♠",
        "Barcode": "10SS",
        "Number": 10,
        "Code": "4L",
        "CName": "Ten of Spades",
        "Deck": 2.0,
        "CId": 10
    },
    {
        "ID": 92,
        "CardName": "10♣",
        "Barcode": "10CC",
        "Number": 10,
        "Code": "4M",
        "CName": "Ten of Clubs",
        "Deck": 2.0,
        "CId": 49
    },
    {
        "ID": 93,
        "CardName": "10♦",
        "Barcode": "10DD",
        "Number": 10,
        "Code": "4N",
        "CName": "Ten of Diamonds",
        "Deck": 2.0,
        "CId": 36
    },
    {
        "ID": 94,
        "CardName": "J♥",
        "Barcode": "JHH",
        "Number": 11,
        "Code": "4O",
        "CName": "Jack of Hearts",
        "Deck": 2.0,
        "CId": 24
    },
    {
        "ID": 95,
        "CardName": "J♠",
        "Barcode": "JSS",
        "Number": 11,
        "Code": "4P",
        "CName": "Jack of Spades",
        "Deck": 2.0,
        "CId": 11
    },
    {
        "ID": 96,
        "CardName": "J♣",
        "Barcode": "JCC",
        "Number": 11,
        "Code": "4Q",
        "CName": "Jack of Clubs",
        "Deck": 2.0,
        "CId": 50
    },
    {
        "ID": 97,
        "CardName": "J♦",
        "Barcode": "JDD",
        "Number": 11,
        "Code": "4R",
        "CName": "Jack of Diamonds",
        "Deck": 2.0,
        "CId": 37
    },
    {
        "ID": 98,
        "CardName": "Q♥",
        "Barcode": "QHH",
        "Number": 12,
        "Code": "4S",
        "CName": "Queen of Hearts",
        "Deck": 2.0,
        "CId": 25
    },
    {
        "ID": 99,
        "CardName": "Q♠",
        "Barcode": "QSS",
        "Number": 12,
        "Code": "4T",
        "CName": "Queen of Spades",
        "Deck": 2.0,
        "CId": 12
    },
    {
        "ID": 100,
        "CardName": "Q♣",
        "Barcode": "QCC",
        "Number": 12,
        "Code": "4U",
        "CName": "Queen of Clubs",
        "Deck": 2.0,
        "CId": 51
    },
    {
        "ID": 101,
        "CardName": "Q♦",
        "Barcode": "QDD",
        "Number": 12,
        "Code": "4V",
        "CName": "Queen of Diamonds",
        "Deck": 2.0,
        "CId": 38
    },
    {
        "ID": 102,
        "CardName": "K♥",
        "Barcode": "KHH",
        "Number": 13,
        "Code": "4W",
        "CName": "King of Hearts",
        "Deck": 2.0,
        "CId": 26
    },
    {
        "ID": 103,
        "CardName": "K♠",
        "Barcode": "KSS",
        "Number": 13,
        "Code": "4X",
        "CName": "King of Spades",
        "Deck": 2.0,
        "CId": 13
    },
    {
        "ID": 104,
        "CardName": "K♣",
        "Barcode": "KCC",
        "Number": 13,
        "Code": "4Y",
        "CName": "King of Clubs",
        "Deck": 2.0,
        "CId": 52
    },
    {
        "ID": 105,
        "CardName": "K♦",
        "Barcode": "KDD",
        "Number": 13,
        "Code": "4Z",
        "CName": "King of Diamonds",
        "Deck": 2.0,
        "CId": 39
    },
    {
        "ID": 106,
        "CardName": "A♥",
        "Barcode": "AHH",
        "Number": 1,
        "Code": "5A",
        "CName": "Ace of Hearts",
        "Deck": 3.0,
        "CId": 14
    },
    {
        "ID": 107,
        "CardName": "A♠",
        "Barcode": "ASS",
        "Number": 1,
        "Code": "5B",
        "CName": "Ace of Spades",
        "Deck": 3.0,
        "CId": 1
    },
    {
        "ID": 108,
        "CardName": "A♣",
        "Barcode": "ACC",
        "Number": 1,
        "Code": "5C",
        "CName": "Ace of Clubs",
        "Deck": 3.0,
        "CId": 40
    },
    {
        "ID": 109,
        "CardName": "A♦",
        "Barcode": "ADD",
        "Number": 1,
        "Code": "5D",
        "CName": "Ace of Diamonds",
        "Deck": 3.0,
        "CId": 27
    },
    {
        "ID": 110,
        "CardName": "2♥",
        "Barcode": "2HH",
        "Number": 2,
        "Code": "5E",
        "CName": "Two of Hearts",
        "Deck": 3.0,
        "CId": 15
    },
    {
        "ID": 111,
        "CardName": "2♠",
        "Barcode": "2SS",
        "Number": 2,
        "Code": "5F",
        "CName": "Two of Spades",
        "Deck": 3.0,
        "CId": 2
    },
    {
        "ID": 112,
        "CardName": "2♣",
        "Barcode": "2CC",
        "Number": 2,
        "Code": "5G",
        "CName": "Two of Clubs",
        "Deck": 3.0,
        "CId": 41
    },
    {
        "ID": 113,
        "CardName": "2♦",
        "Barcode": "2DD",
        "Number": 2,
        "Code": "5H",
        "CName": "Two of Diamonds",
        "Deck": 3.0,
        "CId": 28
    },
    {
        "ID": 114,
        "CardName": "3♥",
        "Barcode": "3HH",
        "Number": 3,
        "Code": "5I",
        "CName": "Three of Hearts",
        "Deck": 3.0,
        "CId": 16
    },
    {
        "ID": 115,
        "CardName": "3♠",
        "Barcode": "3SS",
        "Number": 3,
        "Code": "5J",
        "CName": "Three of Spades",
        "Deck": 3.0,
        "CId": 3
    },
    {
        "ID": 116,
        "CardName": "3♣",
        "Barcode": "3CC",
        "Number": 3,
        "Code": "5K",
        "CName": "Three of Clubs",
        "Deck": 3.0,
        "CId": 42
    },
    {
        "ID": 117,
        "CardName": "3♦",
        "Barcode": "3DD",
        "Number": 3,
        "Code": "5L",
        "CName": "Three of Diamonds",
        "Deck": 3.0,
        "CId": 29
    },
    {
        "ID": 118,
        "CardName": "4♥",
        "Barcode": "4HH",
        "Number": 4,
        "Code": "5M",
        "CName": "Four of Hearts",
        "Deck": 3.0,
        "CId": 17
    },
    {
        "ID": 119,
        "CardName": "4♠",
        "Barcode": "4SS",
        "Number": 4,
        "Code": "5N",
        "CName": "Four of Spades",
        "Deck": 3.0,
        "CId": 4
    },
    {
        "ID": 120,
        "CardName": "4♣",
        "Barcode": "4CC",
        "Number": 4,
        "Code": "5O",
        "CName": "Four of Clubs",
        "Deck": 3.0,
        "CId": 43
    },
    {
        "ID": 121,
        "CardName": "4♦",
        "Barcode": "4DD",
        "Number": 4,
        "Code": "5P",
        "CName": "Four of Diamonds",
        "Deck": 3.0,
        "CId": 30
    },
    {
        "ID": 122,
        "CardName": "5♥",
        "Barcode": "5HH",
        "Number": 5,
        "Code": "5Q",
        "CName": "Five of Hearts",
        "Deck": 3.0,
        "CId": 18
    },
    {
        "ID": 123,
        "CardName": "5♠",
        "Barcode": "5SS",
        "Number": 5,
        "Code": "5R",
        "CName": "Five of Spades",
        "Deck": 3.0,
        "CId": 5
    },
    {
        "ID": 124,
        "CardName": "5♣",
        "Barcode": "5CC",
        "Number": 5,
        "Code": "5S",
        "CName": "Five of Clubs",
        "Deck": 3.0,
        "CId": 44
    },
    {
        "ID": 125,
        "CardName": "5♦",
        "Barcode": "5DD",
        "Number": 5,
        "Code": "5T",
        "CName": "Five of Diamonds",
        "Deck": 3.0,
        "CId": 31
    },
    {
        "ID": 126,
        "CardName": "6♥",
        "Barcode": "6HH",
        "Number": 6,
        "Code": "5U",
        "CName": "Six of Hearts",
        "Deck": 3.0,
        "CId": 19
    },
    {
        "ID": 127,
        "CardName": "6♠",
        "Barcode": "6SS",
        "Number": 6,
        "Code": "5V",
        "CName": "Six of Spades",
        "Deck": 3.0,
        "CId": 6
    },
    {
        "ID": 128,
        "CardName": "6♣",
        "Barcode": "6CC",
        "Number": 6,
        "Code": "5W",
        "CName": "Six of Clubs",
        "Deck": 3.0,
        "CId": 45
    },
    {
        "ID": 129,
        "CardName": "6♦",
        "Barcode": "6DD",
        "Number": 6,
        "Code": "5X",
        "CName": "Six of Diamonds",
        "Deck": 3.0,
        "CId": 32
    },
    {
        "ID": 130,
        "CardName": "7♥",
        "Barcode": "7HH",
        "Number": 7,
        "Code": "5Y",
        "CName": "Seven of Hearts",
        "Deck": 3.0,
        "CId": 20
    },
    {
        "ID": 131,
        "CardName": "7♠",
        "Barcode": "7SS",
        "Number": 7,
        "Code": "5Z",
        "CName": "Seven of Spades",
        "Deck": 3.0,
        "CId": 7
    },
    {
        "ID": 132,
        "CardName": "7♣",
        "Barcode": "7CC",
        "Number": 7,
        "Code": "6A",
        "CName": "Seven of Clubs",
        "Deck": 3.0,
        "CId": 46
    },
    {
        "ID": 133,
        "CardName": "7♦",
        "Barcode": "7DD",
        "Number": 7,
        "Code": "6B",
        "CName": "Seven of Diamonds",
        "Deck": 3.0,
        "CId": 33
    },
    {
        "ID": 134,
        "CardName": "8♥",
        "Barcode": "8HH",
        "Number": 8,
        "Code": "6C",
        "CName": "Eight of Hearts",
        "Deck": 3.0,
        "CId": 21
    },
    {
        "ID": 135,
        "CardName": "8♠",
        "Barcode": "8SS",
        "Number": 8,
        "Code": "6D",
        "CName": "Eight of Spades",
        "Deck": 3.0,
        "CId": 8
    },
    {
        "ID": 136,
        "CardName": "8♣",
        "Barcode": "8CC",
        "Number": 8,
        "Code": "6E",
        "CName": "Eight of Clubs",
        "Deck": 3.0,
        "CId": 47
    },
    {
        "ID": 137,
        "CardName": "8♦",
        "Barcode": "8DD",
        "Number": 8,
        "Code": "6F",
        "CName": "Eight of Diamonds",
        "Deck": 3.0,
        "CId": 34
    },
    {
        "ID": 138,
        "CardName": "9♥",
        "Barcode": "9HH",
        "Number": 9,
        "Code": "6G",
        "CName": "Nine of Hearts",
        "Deck": 3.0,
        "CId": 22
    },
    {
        "ID": 139,
        "CardName": "9♠",
        "Barcode": "9SS",
        "Number": 9,
        "Code": "6H",
        "CName": "Nine of Spades",
        "Deck": 3.0,
        "CId": 9
    },
    {
        "ID": 140,
        "CardName": "9♣",
        "Barcode": "9CC",
        "Number": 9,
        "Code": "6I",
        "CName": "Nine of Clubs",
        "Deck": 3.0,
        "CId": 48
    },
    {
        "ID": 141,
        "CardName": "9♦",
        "Barcode": "9DD",
        "Number": 9,
        "Code": "6J",
        "CName": "Nine of Diamonds",
        "Deck": 3.0,
        "CId": 35
    },
    {
        "ID": 142,
        "CardName": "10♥",
        "Barcode": "10HH",
        "Number": 10,
        "Code": "6K",
        "CName": "Ten of Hearts",
        "Deck": 3.0,
        "CId": 23
    },
    {
        "ID": 143,
        "CardName": "10♠",
        "Barcode": "10SS",
        "Number": 10,
        "Code": "6L",
        "CName": "Ten of Spades",
        "Deck": 3.0,
        "CId": 10
    },
    {
        "ID": 144,
        "CardName": "10♣",
        "Barcode": "10CC",
        "Number": 10,
        "Code": "6M",
        "CName": "Ten of Clubs",
        "Deck": 3.0,
        "CId": 49
    },
    {
        "ID": 145,
        "CardName": "10♦",
        "Barcode": "10DD",
        "Number": 10,
        "Code": "6N",
        "CName": "Ten of Diamonds",
        "Deck": 3.0,
        "CId": 36
    },
    {
        "ID": 146,
        "CardName": "J♥",
        "Barcode": "JHH",
        "Number": 11,
        "Code": "6O",
        "CName": "Jack of Hearts",
        "Deck": 3.0,
        "CId": 24
    },
    {
        "ID": 147,
        "CardName": "J♠",
        "Barcode": "JSS",
        "Number": 11,
        "Code": "6P",
        "CName": "Jack of Spades",
        "Deck": 3.0,
        "CId": 11
    },
    {
        "ID": 148,
        "CardName": "J♣",
        "Barcode": "JCC",
        "Number": 11,
        "Code": "6Q",
        "CName": "Jack of Clubs",
        "Deck": 3.0,
        "CId": 50
    },
    {
        "ID": 149,
        "CardName": "J♦",
        "Barcode": "JDD",
        "Number": 11,
        "Code": "6R",
        "CName": "Jack of Diamonds",
        "Deck": 3.0,
        "CId": 37
    },
    {
        "ID": 150,
        "CardName": "Q♥",
        "Barcode": "QHH",
        "Number": 12,
        "Code": "6S",
        "CName": "Queen of Hearts",
        "Deck": 3.0,
        "CId": 25
    },
    {
        "ID": 151,
        "CardName": "Q♠",
        "Barcode": "QSS",
        "Number": 12,
        "Code": "6T",
        "CName": "Queen of Spades",
        "Deck": 3.0,
        "CId": 12
    },
    {
        "ID": 152,
        "CardName": "Q♣",
        "Barcode": "QCC",
        "Number": 12,
        "Code": "6U",
        "CName": "Queen of Clubs",
        "Deck": 3.0,
        "CId": 51
    },
    {
        "ID": 153,
        "CardName": "Q♦",
        "Barcode": "QDD",
        "Number": 12,
        "Code": "6V",
        "CName": "Queen of Diamonds",
        "Deck": 3.0,
        "CId": 38
    },
    {
        "ID": 154,
        "CardName": "K♥",
        "Barcode": "KHH",
        "Number": 13,
        "Code": "6W",
        "CName": "King of Hearts",
        "Deck": 3.0,
        "CId": 26
    },
    {
        "ID": 155,
        "CardName": "K♠",
        "Barcode": "KSS",
        "Number": 13,
        "Code": "6X",
        "CName": "King of Spades",
        "Deck": 3.0,
        "CId": 13
    },
    {
        "ID": 156,
        "CardName": "K♣",
        "Barcode": "KCC",
        "Number": 13,
        "Code": "6Y",
        "CName": "King of Clubs",
        "Deck": 3.0,
        "CId": 52
    },
    {
        "ID": 157,
        "CardName": "K♦",
        "Barcode": "KDD",
        "Number": 13,
        "Code": "6Z",
        "CName": "King of Diamonds",
        "Deck": 3.0,
        "CId": 39
    },
    {
        "ID": 158,
        "CardName": "A♥",
        "Barcode": "AHH",
        "Number": 1,
        "Code": "7A",
        "CName": "Ace of Hearts",
        "Deck": 4.0,
        "CId": 14
    },
    {
        "ID": 159,
        "CardName": "A♠",
        "Barcode": "ASS",
        "Number": 1,
        "Code": "7B",
        "CName": "Ace of Spades",
        "Deck": 4.0,
        "CId": 1
    },
    {
        "ID": 160,
        "CardName": "A♣",
        "Barcode": "ACC",
        "Number": 1,
        "Code": "7C",
        "CName": "Ace of Clubs",
        "Deck": 4.0,
        "CId": 40
    },
    {
        "ID": 161,
        "CardName": "A♦",
        "Barcode": "ADD",
        "Number": 1,
        "Code": "7D",
        "CName": "Ace of Diamonds",
        "Deck": 4.0,
        "CId": 27
    },
    {
        "ID": 162,
        "CardName": "2♥",
        "Barcode": "2HH",
        "Number": 2,
        "Code": "7E",
        "CName": "Two of Hearts",
        "Deck": 4.0,
        "CId": 15
    },
    {
        "ID": 163,
        "CardName": "2♠",
        "Barcode": "2SS",
        "Number": 2,
        "Code": "7F",
        "CName": "Two of Spades",
        "Deck": 4.0,
        "CId": 2
    },
    {
        "ID": 164,
        "CardName": "2♣",
        "Barcode": "2CC",
        "Number": 2,
        "Code": "7G",
        "CName": "Two of Clubs",
        "Deck": 4.0,
        "CId": 41
    },
    {
        "ID": 165,
        "CardName": "2♦",
        "Barcode": "2DD",
        "Number": 2,
        "Code": "7H",
        "CName": "Two of Diamonds",
        "Deck": 4.0,
        "CId": 28
    },
    {
        "ID": 166,
        "CardName": "3♥",
        "Barcode": "3HH",
        "Number": 3,
        "Code": "7I",
        "CName": "Three of Hearts",
        "Deck": 4.0,
        "CId": 16
    },
    {
        "ID": 167,
        "CardName": "3♠",
        "Barcode": "3SS",
        "Number": 3,
        "Code": "7J",
        "CName": "Three of Spades",
        "Deck": 4.0,
        "CId": 3
    },
    {
        "ID": 168,
        "CardName": "3♣",
        "Barcode": "3CC",
        "Number": 3,
        "Code": "7K",
        "CName": "Three of Clubs",
        "Deck": 4.0,
        "CId": 42
    },
    {
        "ID": 169,
        "CardName": "3♦",
        "Barcode": "3DD",
        "Number": 3,
        "Code": "7L",
        "CName": "Three of Diamonds",
        "Deck": 4.0,
        "CId": 29
    },
    {
        "ID": 170,
        "CardName": "4♥",
        "Barcode": "4HH",
        "Number": 4,
        "Code": "7M",
        "CName": "Four of Hearts",
        "Deck": 4.0,
        "CId": 17
    },
    {
        "ID": 171,
        "CardName": "4♠",
        "Barcode": "4SS",
        "Number": 4,
        "Code": "7N",
        "CName": "Four of Spades",
        "Deck": 4.0,
        "CId": 4
    },
    {
        "ID": 172,
        "CardName": "4♣",
        "Barcode": "4CC",
        "Number": 4,
        "Code": "7O",
        "CName": "Four of Clubs",
        "Deck": 4.0,
        "CId": 43
    },
    {
        "ID": 173,
        "CardName": "4♦",
        "Barcode": "4DD",
        "Number": 4,
        "Code": "7P",
        "CName": "Four of Diamonds",
        "Deck": 4.0,
        "CId": 30
    },
    {
        "ID": 174,
        "CardName": "5♥",
        "Barcode": "5HH",
        "Number": 5,
        "Code": "7Q",
        "CName": "Five of Hearts",
        "Deck": 4.0,
        "CId": 18
    },
    {
        "ID": 175,
        "CardName": "5♠",
        "Barcode": "5SS",
        "Number": 5,
        "Code": "7R",
        "CName": "Five of Spades",
        "Deck": 4.0,
        "CId": 5
    },
    {
        "ID": 176,
        "CardName": "5♣",
        "Barcode": "5CC",
        "Number": 5,
        "Code": "7S",
        "CName": "Five of Clubs",
        "Deck": 4.0,
        "CId": 44
    },
    {
        "ID": 177,
        "CardName": "5♦",
        "Barcode": "5DD",
        "Number": 5,
        "Code": "7T",
        "CName": "Five of Diamonds",
        "Deck": 4.0,
        "CId": 31
    },
    {
        "ID": 178,
        "CardName": "6♥",
        "Barcode": "6HH",
        "Number": 6,
        "Code": "7U",
        "CName": "Six of Hearts",
        "Deck": 4.0,
        "CId": 19
    },
    {
        "ID": 179,
        "CardName": "6♠",
        "Barcode": "6SS",
        "Number": 6,
        "Code": "7V",
        "CName": "Six of Spades",
        "Deck": 4.0,
        "CId": 6
    },
    {
        "ID": 180,
        "CardName": "6♣",
        "Barcode": "6CC",
        "Number": 6,
        "Code": "7W",
        "CName": "Six of Clubs",
        "Deck": 4.0,
        "CId": 45
    },
    {
        "ID": 181,
        "CardName": "6♦",
        "Barcode": "6DD",
        "Number": 6,
        "Code": "7X",
        "CName": "Six of Diamonds",
        "Deck": 4.0,
        "CId": 32
    },
    {
        "ID": 182,
        "CardName": "7♥",
        "Barcode": "7HH",
        "Number": 7,
        "Code": "7Y",
        "CName": "Seven of Hearts",
        "Deck": 4.0,
        "CId": 20
    },
    {
        "ID": 183,
        "CardName": "7♠",
        "Barcode": "7SS",
        "Number": 7,
        "Code": "7Z",
        "CName": "Seven of Spades",
        "Deck": 4.0,
        "CId": 7
    },
    {
        "ID": 184,
        "CardName": "7♣",
        "Barcode": "7CC",
        "Number": 7,
        "Code": "8A",
        "CName": "Seven of Clubs",
        "Deck": 4.0,
        "CId": 46
    },
    {
        "ID": 185,
        "CardName": "7♦",
        "Barcode": "7DD",
        "Number": 7,
        "Code": "8B",
        "CName": "Seven of Diamonds",
        "Deck": 4.0,
        "CId": 33
    },
    {
        "ID": 186,
        "CardName": "8♥",
        "Barcode": "8HH",
        "Number": 8,
        "Code": "8C",
        "CName": "Eight of Hearts",
        "Deck": 4.0,
        "CId": 21
    },
    {
        "ID": 187,
        "CardName": "8♠",
        "Barcode": "8SS",
        "Number": 8,
        "Code": "8D",
        "CName": "Eight of Spades",
        "Deck": 4.0,
        "CId": 8
    },
    {
        "ID": 188,
        "CardName": "8♣",
        "Barcode": "8CC",
        "Number": 8,
        "Code": "8E",
        "CName": "Eight of Clubs",
        "Deck": 4.0,
        "CId": 47
    },
    {
        "ID": 189,
        "CardName": "8♦",
        "Barcode": "8DD",
        "Number": 8,
        "Code": "8F",
        "CName": "Eight of Diamonds",
        "Deck": 4.0,
        "CId": 34
    },
    {
        "ID": 190,
        "CardName": "9♥",
        "Barcode": "9HH",
        "Number": 9,
        "Code": "8G",
        "CName": "Nine of Hearts",
        "Deck": 4.0,
        "CId": 22
    },
    {
        "ID": 191,
        "CardName": "9♠",
        "Barcode": "9SS",
        "Number": 9,
        "Code": "8H",
        "CName": "Nine of Spades",
        "Deck": 4.0,
        "CId": 9
    },
    {
        "ID": 192,
        "CardName": "9♣",
        "Barcode": "9CC",
        "Number": 9,
        "Code": "8I",
        "CName": "Nine of Clubs",
        "Deck": 4.0,
        "CId": 48
    },
    {
        "ID": 193,
        "CardName": "9♦",
        "Barcode": "9DD",
        "Number": 9,
        "Code": "8J",
        "CName": "Nine of Diamonds",
        "Deck": 4.0,
        "CId": 35
    },
    {
        "ID": 194,
        "CardName": "10♥",
        "Barcode": "10HH",
        "Number": 10,
        "Code": "8K",
        "CName": "Ten of Hearts",
        "Deck": 4.0,
        "CId": 23
    },
    {
        "ID": 195,
        "CardName": "10♠",
        "Barcode": "10SS",
        "Number": 10,
        "Code": "8L",
        "CName": "Ten of Spades",
        "Deck": 4.0,
        "CId": 10
    },
    {
        "ID": 196,
        "CardName": "10♣",
        "Barcode": "10CC",
        "Number": 10,
        "Code": "8M",
        "CName": "Ten of Clubs",
        "Deck": 4.0,
        "CId": 49
    },
    {
        "ID": 197,
        "CardName": "10♦",
        "Barcode": "10DD",
        "Number": 10,
        "Code": "8N",
        "CName": "Ten of Diamonds",
        "Deck": 4.0,
        "CId": 36
    },
    {
        "ID": 198,
        "CardName": "J♥",
        "Barcode": "JHH",
        "Number": 11,
        "Code": "8O",
        "CName": "Jack of Hearts",
        "Deck": 4.0,
        "CId": 24
    },
    {
        "ID": 199,
        "CardName": "J♠",
        "Barcode": "JSS",
        "Number": 11,
        "Code": "8P",
        "CName": "Jack of Spades",
        "Deck": 4.0,
        "CId": 11
    },
    {
        "ID": 200,
        "CardName": "J♣",
        "Barcode": "JCC",
        "Number": 11,
        "Code": "8Q",
        "CName": "Jack of Clubs",
        "Deck": 4.0,
        "CId": 50
    },
    {
        "ID": 201,
        "CardName": "J♦",
        "Barcode": "JDD",
        "Number": 11,
        "Code": "8R",
        "CName": "Jack of Diamonds",
        "Deck": 4.0,
        "CId": 37
    },
    {
        "ID": 202,
        "CardName": "Q♥",
        "Barcode": "QHH",
        "Number": 12,
        "Code": "8S",
        "CName": "Queen of Hearts",
        "Deck": 4.0,
        "CId": 25
    },
    {
        "ID": 203,
        "CardName": "Q♠",
        "Barcode": "QSS",
        "Number": 12,
        "Code": "8T",
        "CName": "Queen of Spades",
        "Deck": 4.0,
        "CId": 12
    },
    {
        "ID": 204,
        "CardName": "Q♣",
        "Barcode": "QCC",
        "Number": 12,
        "Code": "8U",
        "CName": "Queen of Clubs",
        "Deck": 4.0,
        "CId": 51
    },
    {
        "ID": 205,
        "CardName": "Q♦",
        "Barcode": "QDD",
        "Number": 12,
        "Code": "8V",
        "CName": "Queen of Diamonds",
        "Deck": 4.0,
        "CId": 38
    },
    {
        "ID": 206,
        "CardName": "K♥",
        "Barcode": "KHH",
        "Number": 13,
        "Code": "8W",
        "CName": "King of Hearts",
        "Deck": 4.0,
        "CId": 26
    },
    {
        "ID": 207,
        "CardName": "K♠",
        "Barcode": "KSS",
        "Number": 13,
        "Code": "8X",
        "CName": "King of Spades",
        "Deck": 4.0,
        "CId": 13
    },
    {
        "ID": 208,
        "CardName": "K♣",
        "Barcode": "KCC",
        "Number": 13,
        "Code": "8Y",
        "CName": "King of Clubs",
        "Deck": 4.0,
        "CId": 52
    },
    {
        "ID": 209,
        "CardName": "K♦",
        "Barcode": "KDD",
        "Number": 13,
        "Code": "8Z",
        "CName": "King of Diamonds",
        "Deck": 4.0,
        "CId": 39
    },
    {
        "ID": 210,
        "CardName": "A♥",
        "Barcode": "AHH",
        "Number": 1,
        "Code": "9A",
        "CName": "Ace of Hearts",
        "Deck": 5.0,
        "CId": 14
    },
    {
        "ID": 211,
        "CardName": "A♠",
        "Barcode": "ASS",
        "Number": 1,
        "Code": "9B",
        "CName": "Ace of Spades",
        "Deck": 5.0,
        "CId": 1
    },
    {
        "ID": 212,
        "CardName": "A♣",
        "Barcode": "ACC",
        "Number": 1,
        "Code": "9C",
        "CName": "Ace of Clubs",
        "Deck": 5.0,
        "CId": 40
    },
    {
        "ID": 213,
        "CardName": "A♦",
        "Barcode": "ADD",
        "Number": 1,
        "Code": "9D",
        "CName": "Ace of Diamonds",
        "Deck": 5.0,
        "CId": 27
    },
    {
        "ID": 214,
        "CardName": "2♥",
        "Barcode": "2HH",
        "Number": 2,
        "Code": "9E",
        "CName": "Two of Hearts",
        "Deck": 5.0,
        "CId": 15
    },
    {
        "ID": 215,
        "CardName": "2♠",
        "Barcode": "2SS",
        "Number": 2,
        "Code": "9F",
        "CName": "Two of Spades",
        "Deck": 5.0,
        "CId": 2
    },
    {
        "ID": 216,
        "CardName": "2♣",
        "Barcode": "2CC",
        "Number": 2,
        "Code": "9G",
        "CName": "Two of Clubs",
        "Deck": 5.0,
        "CId": 41
    },
    {
        "ID": 217,
        "CardName": "2♦",
        "Barcode": "2DD",
        "Number": 2,
        "Code": "9H",
        "CName": "Two of Diamonds",
        "Deck": 5.0,
        "CId": 28
    },
    {
        "ID": 218,
        "CardName": "3♥",
        "Barcode": "3HH",
        "Number": 3,
        "Code": "9I",
        "CName": "Three of Hearts",
        "Deck": 5.0,
        "CId": 16
    },
    {
        "ID": 219,
        "CardName": "3♠",
        "Barcode": "3SS",
        "Number": 3,
        "Code": "9J",
        "CName": "Three of Spades",
        "Deck": 5.0,
        "CId": 3
    },
    {
        "ID": 220,
        "CardName": "3♣",
        "Barcode": "3CC",
        "Number": 3,
        "Code": "9K",
        "CName": "Three of Clubs",
        "Deck": 5.0,
        "CId": 42
    },
    {
        "ID": 221,
        "CardName": "3♦",
        "Barcode": "3DD",
        "Number": 3,
        "Code": "9L",
        "CName": "Three of Diamonds",
        "Deck": 5.0,
        "CId": 29
    },
    {
        "ID": 222,
        "CardName": "4♥",
        "Barcode": "4HH",
        "Number": 4,
        "Code": "9M",
        "CName": "Four of Hearts",
        "Deck": 5.0,
        "CId": 17
    },
    {
        "ID": 223,
        "CardName": "4♠",
        "Barcode": "4SS",
        "Number": 4,
        "Code": "9N",
        "CName": "Four of Spades",
        "Deck": 5.0,
        "CId": 4
    },
    {
        "ID": 224,
        "CardName": "4♣",
        "Barcode": "4CC",
        "Number": 4,
        "Code": "9O",
        "CName": "Four of Clubs",
        "Deck": 5.0,
        "CId": 43
    },
    {
        "ID": 225,
        "CardName": "4♦",
        "Barcode": "4DD",
        "Number": 4,
        "Code": "9P",
        "CName": "Four of Diamonds",
        "Deck": 5.0,
        "CId": 30
    },
    {
        "ID": 226,
        "CardName": "5♥",
        "Barcode": "5HH",
        "Number": 5,
        "Code": "9Q",
        "CName": "Five of Hearts",
        "Deck": 5.0,
        "CId": 18
    },
    {
        "ID": 227,
        "CardName": "5♠",
        "Barcode": "5SS",
        "Number": 5,
        "Code": "9R",
        "CName": "Five of Spades",
        "Deck": 5.0,
        "CId": 5
    },
    {
        "ID": 228,
        "CardName": "5♣",
        "Barcode": "5CC",
        "Number": 5,
        "Code": "9S",
        "CName": "Five of Clubs",
        "Deck": 5.0,
        "CId": 44
    },
    {
        "ID": 229,
        "CardName": "5♦",
        "Barcode": "5DD",
        "Number": 5,
        "Code": "9T",
        "CName": "Five of Diamonds",
        "Deck": 5.0,
        "CId": 31
    },
    {
        "ID": 230,
        "CardName": "6♥",
        "Barcode": "6HH",
        "Number": 6,
        "Code": "9U",
        "CName": "Six of Hearts",
        "Deck": 5.0,
        "CId": 19
    },
    {
        "ID": 231,
        "CardName": "6♠",
        "Barcode": "6SS",
        "Number": 6,
        "Code": "9V",
        "CName": "Six of Spades",
        "Deck": 5.0,
        "CId": 6
    },
    {
        "ID": 232,
        "CardName": "6♣",
        "Barcode": "6CC",
        "Number": 6,
        "Code": "9W",
        "CName": "Six of Clubs",
        "Deck": 5.0,
        "CId": 45
    },
    {
        "ID": 233,
        "CardName": "6♦",
        "Barcode": "6DD",
        "Number": 6,
        "Code": "9X",
        "CName": "Six of Diamonds",
        "Deck": 5.0,
        "CId": 32
    },
    {
        "ID": 234,
        "CardName": "7♥",
        "Barcode": "7HH",
        "Number": 7,
        "Code": "9Y",
        "CName": "Seven of Hearts",
        "Deck": 5.0,
        "CId": 20
    },
    {
        "ID": 235,
        "CardName": "7♠",
        "Barcode": "7SS",
        "Number": 7,
        "Code": "9Z",
        "CName": "Seven of Spades",
        "Deck": 5.0,
        "CId": 7
    },
    {
        "ID": 236,
        "CardName": "7♣",
        "Barcode": "7CC",
        "Number": 7,
        "Code": "AA",
        "CName": "Seven of Clubs",
        "Deck": 5.0,
        "CId": 46
    },
    {
        "ID": 237,
        "CardName": "7♦",
        "Barcode": "7DD",
        "Number": 7,
        "Code": "AB",
        "CName": "Seven of Diamonds",
        "Deck": 5.0,
        "CId": 33
    },
    {
        "ID": 238,
        "CardName": "8♥",
        "Barcode": "8HH",
        "Number": 8,
        "Code": "AC",
        "CName": "Eight of Hearts",
        "Deck": 5.0,
        "CId": 21
    },
    {
        "ID": 239,
        "CardName": "8♠",
        "Barcode": "8SS",
        "Number": 8,
        "Code": "AD",
        "CName": "Eight of Spades",
        "Deck": 5.0,
        "CId": 8
    },
    {
        "ID": 240,
        "CardName": "8♣",
        "Barcode": "8CC",
        "Number": 8,
        "Code": "AE",
        "CName": "Eight of Clubs",
        "Deck": 5.0,
        "CId": 47
    },
    {
        "ID": 241,
        "CardName": "8♦",
        "Barcode": "8DD",
        "Number": 8,
        "Code": "AF",
        "CName": "Eight of Diamonds",
        "Deck": 5.0,
        "CId": 34
    },
    {
        "ID": 242,
        "CardName": "9♥",
        "Barcode": "9HH",
        "Number": 9,
        "Code": "AG",
        "CName": "Nine of Hearts",
        "Deck": 5.0,
        "CId": 22
    },
    {
        "ID": 243,
        "CardName": "9♠",
        "Barcode": "9SS",
        "Number": 9,
        "Code": "AH",
        "CName": "Nine of Spades",
        "Deck": 5.0,
        "CId": 9
    },
    {
        "ID": 244,
        "CardName": "9♣",
        "Barcode": "9CC",
        "Number": 9,
        "Code": "AI",
        "CName": "Nine of Clubs",
        "Deck": 5.0,
        "CId": 48
    },
    {
        "ID": 245,
        "CardName": "9♦",
        "Barcode": "9DD",
        "Number": 9,
        "Code": "AJ",
        "CName": "Nine of Diamonds",
        "Deck": 5.0,
        "CId": 35
    },
    {
        "ID": 246,
        "CardName": "10♥",
        "Barcode": "10HH",
        "Number": 10,
        "Code": "AK",
        "CName": "Ten of Hearts",
        "Deck": 5.0,
        "CId": 23
    },
    {
        "ID": 247,
        "CardName": "10♠",
        "Barcode": "10SS",
        "Number": 10,
        "Code": "AL",
        "CName": "Ten of Spades",
        "Deck": 5.0,
        "CId": 10
    },
    {
        "ID": 248,
        "CardName": "10♣",
        "Barcode": "10CC",
        "Number": 10,
        "Code": "AM",
        "CName": "Ten of Clubs",
        "Deck": 5.0,
        "CId": 49
    },
    {
        "ID": 249,
        "CardName": "10♦",
        "Barcode": "10DD",
        "Number": 10,
        "Code": "AN",
        "CName": "Ten of Diamonds",
        "Deck": 5.0,
        "CId": 36
    },
    {
        "ID": 250,
        "CardName": "J♥",
        "Barcode": "JHH",
        "Number": 11,
        "Code": "AO",
        "CName": "Jack of Hearts",
        "Deck": 5.0,
        "CId": 24
    },
    {
        "ID": 251,
        "CardName": "J♠",
        "Barcode": "JSS",
        "Number": 11,
        "Code": "AP",
        "CName": "Jack of Spades",
        "Deck": 5.0,
        "CId": 11
    },
    {
        "ID": 252,
        "CardName": "J♣",
        "Barcode": "JCC",
        "Number": 11,
        "Code": "AQ",
        "CName": "Jack of Clubs",
        "Deck": 5.0,
        "CId": 50
    },
    {
        "ID": 253,
        "CardName": "J♦",
        "Barcode": "JDD",
        "Number": 11,
        "Code": "AR",
        "CName": "Jack of Diamonds",
        "Deck": 5.0,
        "CId": 37
    },
    {
        "ID": 254,
        "CardName": "Q♥",
        "Barcode": "QHH",
        "Number": 12,
        "Code": "AS",
        "CName": "Queen of Hearts",
        "Deck": 5.0,
        "CId": 25
    },
    {
        "ID": 255,
        "CardName": "Q♠",
        "Barcode": "QSS",
        "Number": 12,
        "Code": "AT",
        "CName": "Queen of Spades",
        "Deck": 5.0,
        "CId": 12
    },
    {
        "ID": 256,
        "CardName": "Q♣",
        "Barcode": "QCC",
        "Number": 12,
        "Code": "AU",
        "CName": "Queen of Clubs",
        "Deck": 5.0,
        "CId": 51
    },
    {
        "ID": 257,
        "CardName": "Q♦",
        "Barcode": "QDD",
        "Number": 12,
        "Code": "AV",
        "CName": "Queen of Diamonds",
        "Deck": 5.0,
        "CId": 38
    },
    {
        "ID": 258,
        "CardName": "K♥",
        "Barcode": "KHH",
        "Number": 13,
        "Code": "AW",
        "CName": "King of Hearts",
        "Deck": 5.0,
        "CId": 26
    },
    {
        "ID": 259,
        "CardName": "K♠",
        "Barcode": "KSS",
        "Number": 13,
        "Code": "AX",
        "CName": "King of Spades",
        "Deck": 5.0,
        "CId": 13
    },
    {
        "ID": 260,
        "CardName": "K♣",
        "Barcode": "KCC",
        "Number": 13,
        "Code": "AY",
        "CName": "King of Clubs",
        "Deck": 5.0,
        "CId": 52
    },
    {
        "ID": 261,
        "CardName": "K♦",
        "Barcode": "KDD",
        "Number": 13,
        "Code": "AZ",
        "CName": "King of Diamonds",
        "Deck": 5.0,
        "CId": 39
    },
    {
        "ID": 262,
        "CardName": "A♥",
        "Barcode": "AHH",
        "Number": 1,
        "Code": "BA",
        "CName": "Ace of Hearts",
        "Deck": 6.0,
        "CId": 14
    },
    {
        "ID": 263,
        "CardName": "A♠",
        "Barcode": "ASS",
        "Number": 1,
        "Code": "BB",
        "CName": "Ace of Spades",
        "Deck": 6.0,
        "CId": 1
    },
    {
        "ID": 264,
        "CardName": "A♣",
        "Barcode": "ACC",
        "Number": 1,
        "Code": "BC",
        "CName": "Ace of Clubs",
        "Deck": 6.0,
        "CId": 40
    },
    {
        "ID": 265,
        "CardName": "A♦",
        "Barcode": "ADD",
        "Number": 1,
        "Code": "BD",
        "CName": "Ace of Diamonds",
        "Deck": 6.0,
        "CId": 27
    },
    {
        "ID": 266,
        "CardName": "2♥",
        "Barcode": "2HH",
        "Number": 2,
        "Code": "BE",
        "CName": "Two of Hearts",
        "Deck": 6.0,
        "CId": 15
    },
    {
        "ID": 267,
        "CardName": "2♠",
        "Barcode": "2SS",
        "Number": 2,
        "Code": "BF",
        "CName": "Two of Spades",
        "Deck": 6.0,
        "CId": 2
    },
    {
        "ID": 268,
        "CardName": "2♣",
        "Barcode": "2CC",
        "Number": 2,
        "Code": "BG",
        "CName": "Two of Clubs",
        "Deck": 6.0,
        "CId": 41
    },
    {
        "ID": 269,
        "CardName": "2♦",
        "Barcode": "2DD",
        "Number": 2,
        "Code": "BH",
        "CName": "Two of Diamonds",
        "Deck": 6.0,
        "CId": 28
    },
    {
        "ID": 270,
        "CardName": "3♥",
        "Barcode": "3HH",
        "Number": 3,
        "Code": "BI",
        "CName": "Three of Hearts",
        "Deck": 6.0,
        "CId": 16
    },
    {
        "ID": 271,
        "CardName": "3♠",
        "Barcode": "3SS",
        "Number": 3,
        "Code": "BJ",
        "CName": "Three of Spades",
        "Deck": 6.0,
        "CId": 3
    },
    {
        "ID": 272,
        "CardName": "3♣",
        "Barcode": "3CC",
        "Number": 3,
        "Code": "BK",
        "CName": "Three of Clubs",
        "Deck": 6.0,
        "CId": 42
    },
    {
        "ID": 273,
        "CardName": "3♦",
        "Barcode": "3DD",
        "Number": 3,
        "Code": "BL",
        "CName": "Three of Diamonds",
        "Deck": 6.0,
        "CId": 29
    },
    {
        "ID": 274,
        "CardName": "4♥",
        "Barcode": "4HH",
        "Number": 4,
        "Code": "BM",
        "CName": "Four of Hearts",
        "Deck": 6.0,
        "CId": 17
    },
    {
        "ID": 275,
        "CardName": "4♠",
        "Barcode": "4SS",
        "Number": 4,
        "Code": "BN",
        "CName": "Four of Spades",
        "Deck": 6.0,
        "CId": 4
    },
    {
        "ID": 276,
        "CardName": "4♣",
        "Barcode": "4CC",
        "Number": 4,
        "Code": "BO",
        "CName": "Four of Clubs",
        "Deck": 6.0,
        "CId": 43
    },
    {
        "ID": 277,
        "CardName": "4♦",
        "Barcode": "4DD",
        "Number": 4,
        "Code": "BP",
        "CName": "Four of Diamonds",
        "Deck": 6.0,
        "CId": 30
    },
    {
        "ID": 278,
        "CardName": "5♥",
        "Barcode": "5HH",
        "Number": 5,
        "Code": "BQ",
        "CName": "Five of Hearts",
        "Deck": 6.0,
        "CId": 18
    },
    {
        "ID": 279,
        "CardName": "5♠",
        "Barcode": "5SS",
        "Number": 5,
        "Code": "BR",
        "CName": "Five of Spades",
        "Deck": 6.0,
        "CId": 5
    },
    {
        "ID": 280,
        "CardName": "5♣",
        "Barcode": "5CC",
        "Number": 5,
        "Code": "BS",
        "CName": "Five of Clubs",
        "Deck": 6.0,
        "CId": 44
    },
    {
        "ID": 281,
        "CardName": "5♦",
        "Barcode": "5DD",
        "Number": 5,
        "Code": "BT",
        "CName": "Five of Diamonds",
        "Deck": 6.0,
        "CId": 31
    },
    {
        "ID": 282,
        "CardName": "6♥",
        "Barcode": "6HH",
        "Number": 6,
        "Code": "BU",
        "CName": "Six of Hearts",
        "Deck": 6.0,
        "CId": 19
    },
    {
        "ID": 283,
        "CardName": "6♠",
        "Barcode": "6SS",
        "Number": 6,
        "Code": "BV",
        "CName": "Six of Spades",
        "Deck": 6.0,
        "CId": 6
    },
    {
        "ID": 284,
        "CardName": "6♣",
        "Barcode": "6CC",
        "Number": 6,
        "Code": "BW",
        "CName": "Six of Clubs",
        "Deck": 6.0,
        "CId": 45
    },
    {
        "ID": 285,
        "CardName": "6♦",
        "Barcode": "6DD",
        "Number": 6,
        "Code": "BX",
        "CName": "Six of Diamonds",
        "Deck": 6.0,
        "CId": 32
    },
    {
        "ID": 286,
        "CardName": "7♥",
        "Barcode": "7HH",
        "Number": 7,
        "Code": "BY",
        "CName": "Seven of Hearts",
        "Deck": 6.0,
        "CId": 20
    },
    {
        "ID": 287,
        "CardName": "7♠",
        "Barcode": "7SS",
        "Number": 7,
        "Code": "BZ",
        "CName": "Seven of Spades",
        "Deck": 6.0,
        "CId": 7
    },
    {
        "ID": 288,
        "CardName": "7♣",
        "Barcode": "7CC",
        "Number": 7,
        "Code": "CA",
        "CName": "Seven of Clubs",
        "Deck": 6.0,
        "CId": 46
    },
    {
        "ID": 289,
        "CardName": "7♦",
        "Barcode": "7DD",
        "Number": 7,
        "Code": "CB",
        "CName": "Seven of Diamonds",
        "Deck": 6.0,
        "CId": 33
    },
    {
        "ID": 290,
        "CardName": "8♥",
        "Barcode": "8HH",
        "Number": 8,
        "Code": "CC",
        "CName": "Eight of Hearts",
        "Deck": 6.0,
        "CId": 21
    },
    {
        "ID": 291,
        "CardName": "8♠",
        "Barcode": "8SS",
        "Number": 8,
        "Code": "CD",
        "CName": "Eight of Spades",
        "Deck": 6.0,
        "CId": 8
    },
    {
        "ID": 292,
        "CardName": "8♣",
        "Barcode": "8CC",
        "Number": 8,
        "Code": "CE",
        "CName": "Eight of Clubs",
        "Deck": 6.0,
        "CId": 47
    },
    {
        "ID": 293,
        "CardName": "8♦",
        "Barcode": "8DD",
        "Number": 8,
        "Code": "CF",
        "CName": "Eight of Diamonds",
        "Deck": 6.0,
        "CId": 34
    },
    {
        "ID": 294,
        "CardName": "9♥",
        "Barcode": "9HH",
        "Number": 9,
        "Code": "CG",
        "CName": "Nine of Hearts",
        "Deck": 6.0,
        "CId": 22
    },
    {
        "ID": 295,
        "CardName": "9♠",
        "Barcode": "9SS",
        "Number": 9,
        "Code": "CH",
        "CName": "Nine of Spades",
        "Deck": 6.0,
        "CId": 9
    },
    {
        "ID": 296,
        "CardName": "9♣",
        "Barcode": "9CC",
        "Number": 9,
        "Code": "CI",
        "CName": "Nine of Clubs",
        "Deck": 6.0,
        "CId": 48
    },
    {
        "ID": 297,
        "CardName": "9♦",
        "Barcode": "9DD",
        "Number": 9,
        "Code": "CJ",
        "CName": "Nine of Diamonds",
        "Deck": 6.0,
        "CId": 35
    },
    {
        "ID": 298,
        "CardName": "10♥",
        "Barcode": "10HH",
        "Number": 10,
        "Code": "CK",
        "CName": "Ten of Hearts",
        "Deck": 6.0,
        "CId": 23
    },
    {
        "ID": 299,
        "CardName": "10♠",
        "Barcode": "10SS",
        "Number": 10,
        "Code": "CL",
        "CName": "Ten of Spades",
        "Deck": 6.0,
        "CId": 10
    },
    {
        "ID": 300,
        "CardName": "10♣",
        "Barcode": "10CC",
        "Number": 10,
        "Code": "CM",
        "CName": "Ten of Clubs",
        "Deck": 6.0,
        "CId": 49
    },
    {
        "ID": 301,
        "CardName": "10♦",
        "Barcode": "10DD",
        "Number": 10,
        "Code": "CN",
        "CName": "Ten of Diamonds",
        "Deck": 6.0,
        "CId": 36
    },
    {
        "ID": 302,
        "CardName": "J♥",
        "Barcode": "JHH",
        "Number": 11,
        "Code": "CO",
        "CName": "Jack of Hearts",
        "Deck": 6.0,
        "CId": 24
    },
    {
        "ID": 303,
        "CardName": "J♠",
        "Barcode": "JSS",
        "Number": 11,
        "Code": "CP",
        "CName": "Jack of Spades",
        "Deck": 6.0,
        "CId": 11
    },
    {
        "ID": 304,
        "CardName": "J♣",
        "Barcode": "JCC",
        "Number": 11,
        "Code": "CQ",
        "CName": "Jack of Clubs",
        "Deck": 6.0,
        "CId": 50
    },
    {
        "ID": 305,
        "CardName": "J♦",
        "Barcode": "JDD",
        "Number": 11,
        "Code": "CR",
        "CName": "Jack of Diamonds",
        "Deck": 6.0,
        "CId": 37
    },
    {
        "ID": 306,
        "CardName": "Q♥",
        "Barcode": "QHH",
        "Number": 12,
        "Code": "CS",
        "CName": "Queen of Hearts",
        "Deck": 6.0,
        "CId": 25
    },
    {
        "ID": 307,
        "CardName": "Q♠",
        "Barcode": "QSS",
        "Number": 12,
        "Code": "CT",
        "CName": "Queen of Spades",
        "Deck": 6.0,
        "CId": 12
    },
    {
        "ID": 308,
        "CardName": "Q♣",
        "Barcode": "QCC",
        "Number": 12,
        "Code": "CU",
        "CName": "Queen of Clubs",
        "Deck": 6.0,
        "CId": 51
    },
    {
        "ID": 309,
        "CardName": "Q♦",
        "Barcode": "QDD",
        "Number": 12,
        "Code": "CV",
        "CName": "Queen of Diamonds",
        "Deck": 6.0,
        "CId": 38
    },
    {
        "ID": 310,
        "CardName": "K♥",
        "Barcode": "KHH",
        "Number": 13,
        "Code": "CW",
        "CName": "King of Hearts",
        "Deck": 6.0,
        "CId": 26
    },
    {
        "ID": 311,
        "CardName": "K♠",
        "Barcode": "KSS",
        "Number": 13,
        "Code": "CX",
        "CName": "King of Spades",
        "Deck": 6.0,
        "CId": 13
    },
    {
        "ID": 312,
        "CardName": "K♣",
        "Barcode": "KCC",
        "Number": 13,
        "Code": "CY",
        "CName": "King of Clubs",
        "Deck": 6.0,
        "CId": 52
    },
    {
        "ID": 313,
        "CardName": "K♦",
        "Barcode": "KDD",
        "Number": 13,
        "Code": "CZ",
        "CName": "King of Diamonds",
        "Deck": 6.0,
        "CId": 39
    },
    {
        "ID": 314,
        "CardName": "A♥",
        "Barcode": "AHH",
        "Number": 1,
        "Code": "DA",
        "CName": "Ace of Hearts",
        "Deck": 7.0,
        "CId": 14
    },
    {
        "ID": 315,
        "CardName": "A♠",
        "Barcode": "ASS",
        "Number": 1,
        "Code": "DB",
        "CName": "Ace of Spades",
        "Deck": 7.0,
        "CId": 1
    },
    {
        "ID": 316,
        "CardName": "A♣",
        "Barcode": "ACC",
        "Number": 1,
        "Code": "DC",
        "CName": "Ace of Clubs",
        "Deck": 7.0,
        "CId": 40
    },
    {
        "ID": 317,
        "CardName": "A♦",
        "Barcode": "ADD",
        "Number": 1,
        "Code": "DD",
        "CName": "Ace of Diamonds",
        "Deck": 7.0,
        "CId": 27
    },
    {
        "ID": 318,
        "CardName": "2♥",
        "Barcode": "2HH",
        "Number": 2,
        "Code": "DE",
        "CName": "Two of Hearts",
        "Deck": 7.0,
        "CId": 15
    },
    {
        "ID": 319,
        "CardName": "2♠",
        "Barcode": "2SS",
        "Number": 2,
        "Code": "DF",
        "CName": "Two of Spades",
        "Deck": 7.0,
        "CId": 2
    },
    {
        "ID": 320,
        "CardName": "2♣",
        "Barcode": "2CC",
        "Number": 2,
        "Code": "DG",
        "CName": "Two of Clubs",
        "Deck": 7.0,
        "CId": 41
    },
    {
        "ID": 321,
        "CardName": "2♦",
        "Barcode": "2DD",
        "Number": 2,
        "Code": "DH",
        "CName": "Two of Diamonds",
        "Deck": 7.0,
        "CId": 28
    },
    {
        "ID": 322,
        "CardName": "3♥",
        "Barcode": "3HH",
        "Number": 3,
        "Code": "DI",
        "CName": "Three of Hearts",
        "Deck": 7.0,
        "CId": 16
    },
    {
        "ID": 323,
        "CardName": "3♠",
        "Barcode": "3SS",
        "Number": 3,
        "Code": "DJ",
        "CName": "Three of Spades",
        "Deck": 7.0,
        "CId": 3
    },
    {
        "ID": 324,
        "CardName": "3♣",
        "Barcode": "3CC",
        "Number": 3,
        "Code": "DK",
        "CName": "Three of Clubs",
        "Deck": 7.0,
        "CId": 42
    },
    {
        "ID": 325,
        "CardName": "3♦",
        "Barcode": "3DD",
        "Number": 3,
        "Code": "DL",
        "CName": "Three of Diamonds",
        "Deck": 7.0,
        "CId": 29
    },
    {
        "ID": 326,
        "CardName": "4♥",
        "Barcode": "4HH",
        "Number": 4,
        "Code": "DM",
        "CName": "Four of Hearts",
        "Deck": 7.0,
        "CId": 17
    },
    {
        "ID": 327,
        "CardName": "4♠",
        "Barcode": "4SS",
        "Number": 4,
        "Code": "DN",
        "CName": "Four of Spades",
        "Deck": 7.0,
        "CId": 4
    },
    {
        "ID": 328,
        "CardName": "4♣",
        "Barcode": "4CC",
        "Number": 4,
        "Code": "DO",
        "CName": "Four of Clubs",
        "Deck": 7.0,
        "CId": 43
    },
    {
        "ID": 329,
        "CardName": "4♦",
        "Barcode": "4DD",
        "Number": 4,
        "Code": "DP",
        "CName": "Four of Diamonds",
        "Deck": 7.0,
        "CId": 30
    },
    {
        "ID": 330,
        "CardName": "5♥",
        "Barcode": "5HH",
        "Number": 5,
        "Code": "DQ",
        "CName": "Five of Hearts",
        "Deck": 7.0,
        "CId": 18
    },
    {
        "ID": 331,
        "CardName": "5♠",
        "Barcode": "5SS",
        "Number": 5,
        "Code": "DR",
        "CName": "Five of Spades",
        "Deck": 7.0,
        "CId": 5
    },
    {
        "ID": 332,
        "CardName": "5♣",
        "Barcode": "5CC",
        "Number": 5,
        "Code": "DS",
        "CName": "Five of Clubs",
        "Deck": 7.0,
        "CId": 44
    },
    {
        "ID": 333,
        "CardName": "5♦",
        "Barcode": "5DD",
        "Number": 5,
        "Code": "DT",
        "CName": "Five of Diamonds",
        "Deck": 7.0,
        "CId": 31
    },
    {
        "ID": 334,
        "CardName": "6♥",
        "Barcode": "6HH",
        "Number": 6,
        "Code": "DU",
        "CName": "Six of Hearts",
        "Deck": 7.0,
        "CId": 19
    },
    {
        "ID": 335,
        "CardName": "6♠",
        "Barcode": "6SS",
        "Number": 6,
        "Code": "DV",
        "CName": "Six of Spades",
        "Deck": 7.0,
        "CId": 6
    },
    {
        "ID": 336,
        "CardName": "6♣",
        "Barcode": "6CC",
        "Number": 6,
        "Code": "DW",
        "CName": "Six of Clubs",
        "Deck": 7.0,
        "CId": 45
    },
    {
        "ID": 337,
        "CardName": "6♦",
        "Barcode": "6DD",
        "Number": 6,
        "Code": "DX",
        "CName": "Six of Diamonds",
        "Deck": 7.0,
        "CId": 32
    },
    {
        "ID": 338,
        "CardName": "7♥",
        "Barcode": "7HH",
        "Number": 7,
        "Code": "DY",
        "CName": "Seven of Hearts",
        "Deck": 7.0,
        "CId": 20
    },
    {
        "ID": 339,
        "CardName": "7♠",
        "Barcode": "7SS",
        "Number": 7,
        "Code": "DZ",
        "CName": "Seven of Spades",
        "Deck": 7.0,
        "CId": 7
    },
    {
        "ID": 340,
        "CardName": "7♣",
        "Barcode": "7CC",
        "Number": 7,
        "Code": "EA",
        "CName": "Seven of Clubs",
        "Deck": 7.0,
        "CId": 46
    },
    {
        "ID": 341,
        "CardName": "7♦",
        "Barcode": "7DD",
        "Number": 7,
        "Code": "EB",
        "CName": "Seven of Diamonds",
        "Deck": 7.0,
        "CId": 33
    },
    {
        "ID": 342,
        "CardName": "8♥",
        "Barcode": "8HH",
        "Number": 8,
        "Code": "EC",
        "CName": "Eight of Hearts",
        "Deck": 7.0,
        "CId": 21
    },
    {
        "ID": 343,
        "CardName": "8♠",
        "Barcode": "8SS",
        "Number": 8,
        "Code": "ED",
        "CName": "Eight of Spades",
        "Deck": 7.0,
        "CId": 8
    },
    {
        "ID": 344,
        "CardName": "8♣",
        "Barcode": "8CC",
        "Number": 8,
        "Code": "EE",
        "CName": "Eight of Clubs",
        "Deck": 7.0,
        "CId": 47
    },
    {
        "ID": 345,
        "CardName": "8♦",
        "Barcode": "8DD",
        "Number": 8,
        "Code": "EF",
        "CName": "Eight of Diamonds",
        "Deck": 7.0,
        "CId": 34
    },
    {
        "ID": 346,
        "CardName": "9♥",
        "Barcode": "9HH",
        "Number": 9,
        "Code": "EG",
        "CName": "Nine of Hearts",
        "Deck": 7.0,
        "CId": 22
    },
    {
        "ID": 347,
        "CardName": "9♠",
        "Barcode": "9SS",
        "Number": 9,
        "Code": "EH",
        "CName": "Nine of Spades",
        "Deck": 7.0,
        "CId": 9
    },
    {
        "ID": 348,
        "CardName": "9♣",
        "Barcode": "9CC",
        "Number": 9,
        "Code": "EI",
        "CName": "Nine of Clubs",
        "Deck": 7.0,
        "CId": 48
    },
    {
        "ID": 349,
        "CardName": "9♦",
        "Barcode": "9DD",
        "Number": 9,
        "Code": "EJ",
        "CName": "Nine of Diamonds",
        "Deck": 7.0,
        "CId": 35
    },
    {
        "ID": 350,
        "CardName": "10♥",
        "Barcode": "10HH",
        "Number": 10,
        "Code": "EK",
        "CName": "Ten of Hearts",
        "Deck": 7.0,
        "CId": 23
    },
    {
        "ID": 351,
        "CardName": "10♠",
        "Barcode": "10SS",
        "Number": 10,
        "Code": "EL",
        "CName": "Ten of Spades",
        "Deck": 7.0,
        "CId": 10
    },
    {
        "ID": 352,
        "CardName": "10♣",
        "Barcode": "10CC",
        "Number": 10,
        "Code": "EM",
        "CName": "Ten of Clubs",
        "Deck": 7.0,
        "CId": 49
    },
    {
        "ID": 353,
        "CardName": "10♦",
        "Barcode": "10DD",
        "Number": 10,
        "Code": "EN",
        "CName": "Ten of Diamonds",
        "Deck": 7.0,
        "CId": 36
    },
    {
        "ID": 354,
        "CardName": "J♥",
        "Barcode": "JHH",
        "Number": 11,
        "Code": "EO",
        "CName": "Jack of Hearts",
        "Deck": 7.0,
        "CId": 24
    },
    {
        "ID": 355,
        "CardName": "J♠",
        "Barcode": "JSS",
        "Number": 11,
        "Code": "EP",
        "CName": "Jack of Spades",
        "Deck": 7.0,
        "CId": 11
    },
    {
        "ID": 356,
        "CardName": "J♣",
        "Barcode": "JCC",
        "Number": 11,
        "Code": "EQ",
        "CName": "Jack of Clubs",
        "Deck": 7.0,
        "CId": 50
    },
    {
        "ID": 357,
        "CardName": "J♦",
        "Barcode": "JDD",
        "Number": 11,
        "Code": "ER",
        "CName": "Jack of Diamonds",
        "Deck": 7.0,
        "CId": 37
    },
    {
        "ID": 358,
        "CardName": "Q♥",
        "Barcode": "QHH",
        "Number": 12,
        "Code": "ES",
        "CName": "Queen of Hearts",
        "Deck": 7.0,
        "CId": 25
    },
    {
        "ID": 359,
        "CardName": "Q♠",
        "Barcode": "QSS",
        "Number": 12,
        "Code": "ET",
        "CName": "Queen of Spades",
        "Deck": 7.0,
        "CId": 12
    },
    {
        "ID": 360,
        "CardName": "Q♣",
        "Barcode": "QCC",
        "Number": 12,
        "Code": "EU",
        "CName": "Queen of Clubs",
        "Deck": 7.0,
        "CId": 51
    },
    {
        "ID": 361,
        "CardName": "Q♦",
        "Barcode": "QDD",
        "Number": 12,
        "Code": "EV",
        "CName": "Queen of Diamonds",
        "Deck": 7.0,
        "CId": 38
    },
    {
        "ID": 362,
        "CardName": "K♥",
        "Barcode": "KHH",
        "Number": 13,
        "Code": "EW",
        "CName": "King of Hearts",
        "Deck": 7.0,
        "CId": 26
    },
    {
        "ID": 363,
        "CardName": "K♠",
        "Barcode": "KSS",
        "Number": 13,
        "Code": "EX",
        "CName": "King of Spades",
        "Deck": 7.0,
        "CId": 13
    },
    {
        "ID": 364,
        "CardName": "K♣",
        "Barcode": "KCC",
        "Number": 13,
        "Code": "EY",
        "CName": "King of Clubs",
        "Deck": 7.0,
        "CId": 52
    },
    {
        "ID": 365,
        "CardName": "K♦",
        "Barcode": "KDD",
        "Number": 13,
        "Code": "EZ",
        "CName": "King of Diamonds",
        "Deck": 7.0,
        "CId": 39
    },
    {
        "ID": 366,
        "CardName": "A♥",
        "Barcode": "AHH",
        "Number": 1,
        "Code": "FA",
        "CName": "Ace of Hearts",
        "Deck": 8.0,
        "CId": 14
    },
    {
        "ID": 367,
        "CardName": "A♠",
        "Barcode": "ASS",
        "Number": 1,
        "Code": "FB",
        "CName": "Ace of Spades",
        "Deck": 8.0,
        "CId": 1
    },
    {
        "ID": 368,
        "CardName": "A♣",
        "Barcode": "ACC",
        "Number": 1,
        "Code": "FC",
        "CName": "Ace of Clubs",
        "Deck": 8.0,
        "CId": 40
    },
    {
        "ID": 369,
        "CardName": "A♦",
        "Barcode": "ADD",
        "Number": 1,
        "Code": "FD",
        "CName": "Ace of Diamonds",
        "Deck": 8.0,
        "CId": 27
    },
    {
        "ID": 370,
        "CardName": "2♥",
        "Barcode": "2HH",
        "Number": 2,
        "Code": "FE",
        "CName": "Two of Hearts",
        "Deck": 8.0,
        "CId": 15
    },
    {
        "ID": 371,
        "CardName": "2♠",
        "Barcode": "2SS",
        "Number": 2,
        "Code": "FF",
        "CName": "Two of Spades",
        "Deck": 8.0,
        "CId": 2
    },
    {
        "ID": 372,
        "CardName": "2♣",
        "Barcode": "2CC",
        "Number": 2,
        "Code": "FG",
        "CName": "Two of Clubs",
        "Deck": 8.0,
        "CId": 41
    },
    {
        "ID": 373,
        "CardName": "2♦",
        "Barcode": "2DD",
        "Number": 2,
        "Code": "FH",
        "CName": "Two of Diamonds",
        "Deck": 8.0,
        "CId": 28
    },
    {
        "ID": 374,
        "CardName": "3♥",
        "Barcode": "3HH",
        "Number": 3,
        "Code": "FI",
        "CName": "Three of Hearts",
        "Deck": 8.0,
        "CId": 16
    },
    {
        "ID": 375,
        "CardName": "3♠",
        "Barcode": "3SS",
        "Number": 3,
        "Code": "FJ",
        "CName": "Three of Spades",
        "Deck": 8.0,
        "CId": 3
    },
    {
        "ID": 376,
        "CardName": "3♣",
        "Barcode": "3CC",
        "Number": 3,
        "Code": "FK",
        "CName": "Three of Clubs",
        "Deck": 8.0,
        "CId": 42
    },
    {
        "ID": 377,
        "CardName": "3♦",
        "Barcode": "3DD",
        "Number": 3,
        "Code": "FL",
        "CName": "Three of Diamonds",
        "Deck": 8.0,
        "CId": 29
    },
    {
        "ID": 378,
        "CardName": "4♥",
        "Barcode": "4HH",
        "Number": 4,
        "Code": "FM",
        "CName": "Four of Hearts",
        "Deck": 8.0,
        "CId": 17
    },
    {
        "ID": 379,
        "CardName": "4♠",
        "Barcode": "4SS",
        "Number": 4,
        "Code": "FN",
        "CName": "Four of Spades",
        "Deck": 8.0,
        "CId": 4
    },
    {
        "ID": 380,
        "CardName": "4♣",
        "Barcode": "4CC",
        "Number": 4,
        "Code": "FO",
        "CName": "Four of Clubs",
        "Deck": 8.0,
        "CId": 43
    },
    {
        "ID": 381,
        "CardName": "4♦",
        "Barcode": "4DD",
        "Number": 4,
        "Code": "FP",
        "CName": "Four of Diamonds",
        "Deck": 8.0,
        "CId": 30
    },
    {
        "ID": 382,
        "CardName": "5♥",
        "Barcode": "5HH",
        "Number": 5,
        "Code": "FQ",
        "CName": "Five of Hearts",
        "Deck": 8.0,
        "CId": 18
    },
    {
        "ID": 383,
        "CardName": "5♠",
        "Barcode": "5SS",
        "Number": 5,
        "Code": "FR",
        "CName": "Five of Spades",
        "Deck": 8.0,
        "CId": 5
    },
    {
        "ID": 384,
        "CardName": "5♣",
        "Barcode": "5CC",
        "Number": 5,
        "Code": "FS",
        "CName": "Five of Clubs",
        "Deck": 8.0,
        "CId": 44
    },
    {
        "ID": 385,
        "CardName": "5♦",
        "Barcode": "5DD",
        "Number": 5,
        "Code": "FT",
        "CName": "Five of Diamonds",
        "Deck": 8.0,
        "CId": 31
    },
    {
        "ID": 386,
        "CardName": "6♥",
        "Barcode": "6HH",
        "Number": 6,
        "Code": "FU",
        "CName": "Six of Hearts",
        "Deck": 8.0,
        "CId": 19
    },
    {
        "ID": 387,
        "CardName": "6♠",
        "Barcode": "6SS",
        "Number": 6,
        "Code": "FV",
        "CName": "Six of Spades",
        "Deck": 8.0,
        "CId": 6
    },
    {
        "ID": 388,
        "CardName": "6♣",
        "Barcode": "6CC",
        "Number": 6,
        "Code": "FW",
        "CName": "Six of Clubs",
        "Deck": 8.0,
        "CId": 45
    },
    {
        "ID": 389,
        "CardName": "6♦",
        "Barcode": "6DD",
        "Number": 6,
        "Code": "FX",
        "CName": "Six of Diamonds",
        "Deck": 8.0,
        "CId": 32
    },
    {
        "ID": 390,
        "CardName": "7♥",
        "Barcode": "7HH",
        "Number": 7,
        "Code": "FY",
        "CName": "Seven of Hearts",
        "Deck": 8.0,
        "CId": 20
    },
    {
        "ID": 391,
        "CardName": "7♠",
        "Barcode": "7SS",
        "Number": 7,
        "Code": "FZ",
        "CName": "Seven of Spades",
        "Deck": 8.0,
        "CId": 7
    },
    {
        "ID": 392,
        "CardName": "7♣",
        "Barcode": "7CC",
        "Number": 7,
        "Code": "GA",
        "CName": "Seven of Clubs",
        "Deck": 8.0,
        "CId": 46
    },
    {
        "ID": 393,
        "CardName": "7♦",
        "Barcode": "7DD",
        "Number": 7,
        "Code": "GB",
        "CName": "Seven of Diamonds",
        "Deck": 8.0,
        "CId": 33
    },
    {
        "ID": 394,
        "CardName": "8♥",
        "Barcode": "8HH",
        "Number": 8,
        "Code": "GC",
        "CName": "Eight of Hearts",
        "Deck": 8.0,
        "CId": 21
    },
    {
        "ID": 395,
        "CardName": "8♠",
        "Barcode": "8SS",
        "Number": 8,
        "Code": "GD",
        "CName": "Eight of Spades",
        "Deck": 8.0,
        "CId": 8
    },
    {
        "ID": 396,
        "CardName": "8♣",
        "Barcode": "8CC",
        "Number": 8,
        "Code": "GE",
        "CName": "Eight of Clubs",
        "Deck": 8.0,
        "CId": 47
    },
    {
        "ID": 397,
        "CardName": "8♦",
        "Barcode": "8DD",
        "Number": 8,
        "Code": "GF",
        "CName": "Eight of Diamonds",
        "Deck": 8.0,
        "CId": 34
    },
    {
        "ID": 398,
        "CardName": "9♥",
        "Barcode": "9HH",
        "Number": 9,
        "Code": "GG",
        "CName": "Nine of Hearts",
        "Deck": 8.0,
        "CId": 22
    },
    {
        "ID": 399,
        "CardName": "9♠",
        "Barcode": "9SS",
        "Number": 9,
        "Code": "GH",
        "CName": "Nine of Spades",
        "Deck": 8.0,
        "CId": 9
    },
    {
        "ID": 400,
        "CardName": "9♣",
        "Barcode": "9CC",
        "Number": 9,
        "Code": "GI",
        "CName": "Nine of Clubs",
        "Deck": 8.0,
        "CId": 48
    },
    {
        "ID": 401,
        "CardName": "9♦",
        "Barcode": "9DD",
        "Number": 9,
        "Code": "GJ",
        "CName": "Nine of Diamonds",
        "Deck": 8.0,
        "CId": 35
    },
    {
        "ID": 402,
        "CardName": "10♥",
        "Barcode": "10HH",
        "Number": 10,
        "Code": "GK",
        "CName": "Ten of Hearts",
        "Deck": 8.0,
        "CId": 23
    },
    {
        "ID": 403,
        "CardName": "10♠",
        "Barcode": "10SS",
        "Number": 10,
        "Code": "GL",
        "CName": "Ten of Spades",
        "Deck": 8.0,
        "CId": 10
    },
    {
        "ID": 404,
        "CardName": "10♣",
        "Barcode": "10CC",
        "Number": 10,
        "Code": "GM",
        "CName": "Ten of Clubs",
        "Deck": 8.0,
        "CId": 49
    },
    {
        "ID": 405,
        "CardName": "10♦",
        "Barcode": "10DD",
        "Number": 10,
        "Code": "GN",
        "CName": "Ten of Diamonds",
        "Deck": 8.0,
        "CId": 36
    },
    {
        "ID": 406,
        "CardName": "J♥",
        "Barcode": "JHH",
        "Number": 11,
        "Code": "GO",
        "CName": "Jack of Hearts",
        "Deck": 8.0,
        "CId": 24
    },
    {
        "ID": 407,
        "CardName": "J♠",
        "Barcode": "JSS",
        "Number": 11,
        "Code": "GP",
        "CName": "Jack of Spades",
        "Deck": 8.0,
        "CId": 11
    },
    {
        "ID": 408,
        "CardName": "J♣",
        "Barcode": "JCC",
        "Number": 11,
        "Code": "GQ",
        "CName": "Jack of Clubs",
        "Deck": 8.0,
        "CId": 50
    },
    {
        "ID": 409,
        "CardName": "J♦",
        "Barcode": "JDD",
        "Number": 11,
        "Code": "GR",
        "CName": "Jack of Diamonds",
        "Deck": 8.0,
        "CId": 37
    },
    {
        "ID": 410,
        "CardName": "Q♥",
        "Barcode": "QHH",
        "Number": 12,
        "Code": "GS",
        "CName": "Queen of Hearts",
        "Deck": 8.0,
        "CId": 25
    },
    {
        "ID": 411,
        "CardName": "Q♠",
        "Barcode": "QSS",
        "Number": 12,
        "Code": "GT",
        "CName": "Queen of Spades",
        "Deck": 8.0,
        "CId": 12
    },
    {
        "ID": 412,
        "CardName": "Q♣",
        "Barcode": "QCC",
        "Number": 12,
        "Code": "GU",
        "CName": "Queen of Clubs",
        "Deck": 8.0,
        "CId": 51
    },
    {
        "ID": 413,
        "CardName": "Q♦",
        "Barcode": "QDD",
        "Number": 12,
        "Code": "GV",
        "CName": "Queen of Diamonds",
        "Deck": 8.0,
        "CId": 38
    },
    {
        "ID": 414,
        "CardName": "K♥",
        "Barcode": "KHH",
        "Number": 13,
        "Code": "GW",
        "CName": "King of Hearts",
        "Deck": 8.0,
        "CId": 26
    },
    {
        "ID": 415,
        "CardName": "K♠",
        "Barcode": "KSS",
        "Number": 13,
        "Code": "GX",
        "CName": "King of Spades",
        "Deck": 8.0,
        "CId": 13
    },
    {
        "ID": 416,
        "CardName": "K♣",
        "Barcode": "KCC",
        "Number": 13,
        "Code": "GY",
        "CName": "King of Clubs",
        "Deck": 8.0,
        "CId": 52
    },
    {
        "ID": 417,
        "CardName": "K♦",
        "Barcode": "KDD",
        "Number": 13,
        "Code": "GZ",
        "CName": "King of Diamonds",
        "Deck": 8.0,
        "CId": 39
    }
]