import initialStore from "../initialStore";
import {
    SET_RESULT,
    SET_ROUND_END,
    CANCEL_ROUND
} from "@actions/actionTypes";

export default function isActiveResultReducer(state = initialStore.isActiveResult, action) {
    switch (action.type) {
        case SET_RESULT:
            return action.value;
        case CANCEL_ROUND:
        case SET_ROUND_END:
            return initialStore.isActiveResult;
        default:
            return state;
    }
}
