import {
    SET_SERVER_TIME,
    SET_SUB_TICKER_WORKER, SET_TIMER,
    SET_UN_SUB_TICKER_WORKER
} from "@actions/actionTypes";

export default function workerMiddleware(workerService) {
    return ({dispatch}) => next => (action) => {
        if (typeof action === 'function') return next(action);

        if (action.type === SET_SERVER_TIME) {
            workerService.setTime(action.time)
        }
        if (action.type === SET_TIMER) {
            workerService.setTimeTicker(action.timer)
        }

        if (action.type === SET_SUB_TICKER_WORKER) {
            workerService.subscribe(action.data)
        }

        if (action.type === SET_UN_SUB_TICKER_WORKER) {
            workerService.unSubscribe(action.data)
        }

        return next(action);
    };
}
