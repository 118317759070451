import {createStore, applyMiddleware, compose} from "redux";
import rootReducer from "./reducers";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant"
import thunk from "redux-thunk";
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import {
    SET_ERROR_MSG,
    SET_SOCKET_CONNECTIONS
} from "@actions/actionTypes";
import socketMiddleware from "./middlewares/socketMiddleware";
import loadingMiddleware from "./middlewares/loadingMiddleware";
import workerMiddleware from "./middlewares/workerMiddleware";
import WorkerService from "@services/workerService"
import SocketClient from "../utils/SocketClient";


const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState) {
    const isDev = process.env.NODE_ENV === "development" || process.env.NODE_ENV === "env"
    const composeEnhancers =
        (isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ) || compose; // add support for Redux dev tools

    const worker = new WorkerService();
    const socket = SocketClient();

    const middlewares = [
        thunk,
        sagaMiddleware,
        workerMiddleware(worker),
        socketMiddleware(socket),
        loadingMiddleware()
    ];

    if (isDev) {
        middlewares.splice(1, 0, reduxImmutableStateInvariant());
    }

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    socket.onOpen( function() {
        store.dispatch({type: SET_SOCKET_CONNECTIONS, isConnected: true});
        socket.pingSocket()
        console.log("connect")
    });

    socket.onClose( function() {
        store.dispatch({type: SET_SOCKET_CONNECTIONS, isConnected: false});
        console.log("disconnect")
    });

    socket.onConnectEnd(function (code) {
        store.dispatch({type: SET_ALERT_MSG,  data: {type: 'error', messages: `error_${code}`}})
    });

    sagaMiddleware.run(rootSaga);

    const originalSetTimeout = window.setTimeout;

    window.setTimeout = function(fn, deley) {
        return originalSetTimeout( () => {
            try {
                fn()
            } catch (e) {
                store.dispatch({type: SET_ERROR_MSG, data: {type: 'error', messages: 'error_' + e}})
            }
        }, deley || 0);
    }

    return store;
}
