import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Switch } from "antd";
import { getSettings, changeAutoStart, changeAutoResult } from "@actions/settingsAction";


function SettingsList({settings, getSettings, changeAutoStart, changeAutoResult, loading}) {

    const onChangeAutoStart = (isEnable) => {
        changeAutoStart({
            ...settings,
            autoStart: isEnable
        });
    }

    const onChangeAutoResult = (isEnable) => {
        changeAutoResult({
            ...settings,
            autoResult: isEnable
        });
    }

    useEffect(() => {
        getSettings();
    }, [])

    return (
        <div className="settings-list">
            <div>
                <span><FormattedMessage id="auto_start" /></span>
                <Switch onChange={onChangeAutoStart} checked={settings.autoStart} loading={loading._change_auto_start}/>
            </div>
            <div>
                <span><FormattedMessage id="auto_result" /></span>
                <Switch onChange={onChangeAutoResult} checked={settings.autoResult} loading={loading._change_auto_result}/>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        settings: state.settings.gameConfig,
        loading: state.loading
    }
}

const mapDispatchToProps = {
    getSettings,
    changeAutoStart,
    changeAutoResult
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsList);