import initialStore from "../initialStore";
import {
    REQUEST_CHANGE_AUTO_START,
    REQUEST_CHANGE_AUTO_RESULT,
    SET_CHANGE_AUTO_START,
    SET_CHANGE_AUTO_RESULT,
    SET_SETTINGS
} from "@actions/actionTypes";

export default function settingsReducer(state = initialStore.settings, action) {
    switch (action.type) {
        case SET_SETTINGS: 
            return {
                ...state,
                gameConfig: action.settings
            }
        case SET_CHANGE_AUTO_START:
            return {
                ...state,
                gameConfig: {
                    ...state.gameConfig,
                    autoStart: action.autoStart
                }
            }
        case SET_CHANGE_AUTO_RESULT:
            return {
                ...state,
                gameConfig: {
                    ...state.gameConfig,
                    autoResult: action.autoResult
                }
            }
        default:
            return state;
    }
}
