import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
import {scanCard} from "@actions/cardAction";
import {setErrorMsg} from "@actions/alertAction";
import {getTimeFromTickerWorker} from "@actions/workerActions"
import cardsEnum from "@constants/cards";
import {playWarningSound} from "@services/soundServices";
import { setDealer } from "@actions/dealerActions";

function Scanner({scanCard, ticker, setErrorMsg,getTimeFromTickerWorker, cards, setDealer}) {

    const enteredCard = useRef("")

    useEffect(() => {
         function  keypressListener(e) {
                e.preventDefault();
                e.stopPropagation();

                if (ticker > 3) {
                    playWarningSound();
                    setErrorMsg(ticker !== undefined ? "bet_duration_is_not_over" : "round_is_not_started");
                }
                 
                if (e.code !== "Enter" || e.which != 13) {
                    enteredCard.current += String.fromCharCode(Number(e.charCode));
                }

                if ((e.code === "Enter" || e.which == 13) && enteredCard.current) {
                    if (typeof enteredCard.current == "string") {
                        if (enteredCard.current.length == 10) {
                            setDealer(enteredCard.current);
                        } else {
                            let code = ""
                            try {
                                cardsEnum.forEach(item => {
                                    if (enteredCard.current === item.Code) {
                                        let cardName = item.CardName.slice(0, -1);
                                        let suit = item.CName.split(" ")[2].toLowerCase();
                                        code = cardName + suit;
                                        throw 'Break';
                                    }
                                })
                            } catch (e) {
                                if (e !== 'Break') throw e;
                            }
                            // let existingCardIndex = cards.indexOf(code);
                            // if (existingCardIndex === -1) {
                                scanCard(enteredCard.current);
                                getTimeFromTickerWorker();
                            // } else {
                            //     setErrorMsg("card_already_opened");
                            // }
                        }
                    }
                    enteredCard.current = "";
                }
        }
        document.addEventListener("keypress", keypressListener, true);
        return () => {
            document.removeEventListener("keypress", keypressListener, true);
        }
    }, [ticker])
    return(<div className="scanner"/>)
}

function mapStateToProps(state) {
    return {
        cards: state.cards
    }
}

const mapDispatchToProps = {
    scanCard,
    setErrorMsg,
    getTimeFromTickerWorker,
    setDealer
}

export default connect(mapStateToProps, mapDispatchToProps)(Scanner)