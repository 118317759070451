import initialStore from "../initialStore";
import { SET_ACTIVE_AREA } from "@actions/actionTypes";

export default function activeAreaReducer(state = initialStore.activeArea, action) {
    switch (action.type) {
        case SET_ACTIVE_AREA:
            return  action.activeArea;
        default:
            return state;
    }
}
