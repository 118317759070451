import initialStore from "../initialStore";
import {
    SET_SOCKET_CONNECTIONS
} from "@actions/actionTypes";

export default function globalInfoReducer(state = initialStore.globalInfo, action) {
    switch (action.type) {
        case SET_SOCKET_CONNECTIONS:
            return {...state, isConnected: action.isConnected};
        default:
            return state;
    }
}
