import { SET_WINNER } from "@actions/actionTypes";

export default function winnerReducer(state = 0, action) {
    switch (action.type) {
        case SET_WINNER:
            return  action.winner
        default:
            return state;
    }
}
