import initialStore from "../initialStore";
import { SET_TIMER, CANCEL_ROUND, SET_ROUND_END } from "@actions/actionTypes";

export default function timerReducer(state = initialStore.timer, action) {
    switch (action.type) {
        case SET_TIMER:
            return  action.timer;
        case CANCEL_ROUND:
        case SET_ROUND_END:
            return initialStore.timer;
        default:
            return state;
    }
}
